import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftStore } from './draft'
import { useLoginStore } from './login'
import { useMiscBandSignupReferralStore } from './miscBandSignupReferral'
import { useMiscDraftStore } from './miscDraft'
import { useMiscDraftProgressivePromosStore } from './miscDraftProgressivePromos'
import { usePayinBillingInfosStore } from './payinBillingInfos'
import { useUserAgencyStore } from './userAgency'
import { useUserBandStore } from './userBand'
import { useUserBandSetStore } from './userBandSet'
import { useUserFavoritesStore } from './userFavorites'
import { useUserInfluencerStore } from './userInfluencer'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import primitiveArraysAreEquals from '~/helpers/primitiveArraysAreEquals'

import { arrayUniqueValues } from '~/utils/arrays'

import { provideGetUser } from '~/api-core/Account/AuthUser'
import { fetchSubmissionMetaData } from '~/api-core/Submissions/FetchSubmissionMetaData'
import { provideFetchWallet } from '~/api-core/Wallet/Fetch'

import type { UserAgencyState } from './userAgency'
import type { UserBandState } from './userBand'
import type { UserBandSetState } from './userBandSet'
import type { UserFavoritesState } from './userFavorites'
import type { UserInfluencerState } from './userInfluencer'
import type { UserSudoState } from './userSudo'
import type { Locale } from '~/types/locale'
import type { UserKind } from '~/types/UserKind'

export interface SubmissionMetaResponse {
  answered: number
  sent: number
  refused: number
} // This is the exact order of the axios response

export type SubmissionCounter = [number, number, number]

type SubmissionMetaResponseKeys = keyof SubmissionMetaResponse

const initialSubmissions = () => [0, 0, 0] as SubmissionCounter

const initialState = () => ({
  email: '',
  first_name: '',
  first_payment_date: null as string | null,
  grooviz: 0,
  id: 0,
  is_active: false,
  is_facebook: false,
  is_google: false,
  is_staff: false,
  is_verified_mail: false,
  lang: 'en' as Locale,
  last_name: '',
  picture: '' as string | null,
  submissions: [...initialSubmissions()] as SubmissionCounter,
  google_token: '' as string | null,
  is_soundcloud: false,
  soundcloud_code: '' as string | null,
  soundcloud_token: '' as string | null,
})

const state = initialState

export type IUserState = ReturnType<typeof state>

export interface UserState extends IUserState {
  agency: UserAgencyState
  band_set: UserBandSetState
  band: UserBandState
  favorites: UserFavoritesState
  influencer: UserInfluencerState
  sudo: UserSudoState
  email_disabled?: boolean
}

export const useUserStore = defineStore('user', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const userAgencyStore = useUserAgencyStore($pinia)
  const userBandStore = useUserBandStore($pinia)
  const userBandSetStore = useUserBandSetStore($pinia)
  const userInfluencerStore = useUserInfluencerStore($pinia)
  const loginStore = useLoginStore($pinia)
  const miscDraftProgressivePromosStore =
    useMiscDraftProgressivePromosStore($pinia)
  const userFavoritesStore = useUserFavoritesStore($pinia)
  const draftStore = useDraftStore($pinia)
  const payinBillingInfosStore = usePayinBillingInfosStore($pinia)
  const miscDraftStore = useMiscDraftStore($pinia)
  const miscBandSignupReferralStore = useMiscBandSignupReferralStore($pinia)

  const email = ref('')
  const first_name = ref('')
  const first_payment_date = ref<string | null>(null)
  const grooviz = ref(0)
  const id = ref(0)
  const is_active = ref(false)
  const is_facebook = ref(false)
  const is_google = ref(false)
  const is_staff = ref(false)
  const is_verified_mail = ref(false)
  const lang = ref<Locale>('en')
  const last_name = ref('')
  const picture = ref<string | null>('')
  const submissions = ref<SubmissionCounter>([...initialSubmissions()])
  const google_token = ref<string | null>('')
  const is_soundcloud = ref(false)
  const soundcloud_code = ref<string | null>('')
  const soundcloud_token = ref<string | null>('')

  function getStateRef(key: keyof IUserState) {
    switch (key) {
      case 'email':
        return email
      case 'first_name':
        return first_name
      case 'first_payment_date':
        return first_payment_date
      case 'grooviz':
        return grooviz
      case 'id':
        return id
      case 'is_active':
        return is_active
      case 'is_facebook':
        return is_facebook
      case 'is_google':
        return is_google
      case 'is_staff':
        return is_staff
      case 'is_verified_mail':
        return is_verified_mail
      case 'lang':
        return lang
      case 'last_name':
        return last_name
      case 'picture':
        return picture
      case 'submissions':
        return submissions
      case 'google_token':
        return google_token
      case 'is_soundcloud':
        return is_soundcloud
      case 'soundcloud_code':
        return soundcloud_code
      case 'soundcloud_token':
        return soundcloud_token
    }
  }

  function RESET() {
    userInfluencerStore.RESET()
    userFavoritesStore.RESET()
    userBandSetStore.RESET()
    userBandStore.RESET()
    draftStore.RESET()
    payinBillingInfosStore.RESET()
    userAgencyStore.LOGOUT() // Just calls RESET_STATE
    miscDraftStore.RESET()
    miscBandSignupReferralStore.SET_CODE('')

    Object.keys(initialState()).forEach((key) => {
      const ref = getStateRef(key as keyof IUserState)
      if (ref) ref.value = initialState()[key as keyof IUserState]
    })
  }

  function SET(data: Partial<IUserState>) {
    const patchKeys = Object.keys(data) as (keyof IUserState)[]
    patchKeys.forEach((key) => {
      const ref = getStateRef(key)
      // @ts-expect-error typeguards don't like this, but we need to be able to set falsy values
      if (ref) ref.value = data[key]
    })
  }

  function SET_GROOVIZ(newGrooviz: IUserState['grooviz']) {
    grooviz.value = newGrooviz
  }

  function SET_WALLET({
    newGrooviz,
    firstPaymentDate,
  }: {
    newGrooviz: IUserState['grooviz']
    firstPaymentDate: IUserState['first_payment_date']
  }) {
    grooviz.value = newGrooviz
    first_payment_date.value = firstPaymentDate
  }

  async function LOAD() {
    try {
      const getUser = provideGetUser(coreFetch)
      const { user, influencer, agency } = await getUser()

      if (!user) throw new Error('No user found')

      SET(user)
      if (influencer) userInfluencerStore.SET(influencer)

      if (agency) {
        userAgencyStore.SET(agency)

        const [newSubmissions] = await Promise.all([
          GET_USER_SUB_COUNT(),
          userBandSetStore.FETCH(),
          userBandStore.FETCH(),
        ])
        submissions.value = newSubmissions
      }

      return user as UserState
    } catch (_) {
      return {}
    }
  }

  function GET_USER_SUB_COUNT() {
    const fetchSubmissionMetaDataProvider = fetchSubmissionMetaData(coreFetch)
    return fetchSubmissionMetaDataProvider()
      .then((response: SubmissionMetaResponse) => {
        const keys = Object.keys(response) as SubmissionMetaResponseKeys[]
        return keys.reduce(
          (
            accumulator: SubmissionCounter,
            key: SubmissionMetaResponseKeys,
            index: number,
          ) => {
            accumulator[index] = response[key]
            return accumulator
          },
          [0, 0, 0],
        ) as SubmissionCounter
      })
      .catch(() => {
        return [0, 0, 0] as SubmissionCounter
      })
  }

  async function GET_USER_WALLET(): Promise<{
    newGrooviz: IUserState['grooviz']
    firstPaymentDate: IUserState['first_payment_date']
  }> {
    const response = await provideFetchWallet(coreFetch)()
    return {
      newGrooviz: Number(response?.groovies) || 0,
      firstPaymentDate: response?.first_payment_date ?? '',
    }
  }

  async function LOGOUT() {
    loginStore.SET_LOADING_STATUS(true)
    miscDraftProgressivePromosStore.SET_LIST([])
    await coreFetch.$post('/auth/logout/')

    RESET()

    loginStore.SET_LOADING_STATUS(false)
  }

  async function UPDATE_GROOVIZ(): Promise<{
    oldUserGrooviz: number
    newUserGrooviz: number
  }> {
    const userGrooviz = grooviz.value
    const walletData = await GET_USER_WALLET()
    SET_WALLET(walletData)

    return {
      oldUserGrooviz: userGrooviz,
      newUserGrooviz: grooviz.value,
    }
  }

  const KIND = computed<UserKind | null>(() => {
    if (userAgencyStore.id > 0 && userBandSetStore.list.length > 0)
      return 'band'
    if (userInfluencerStore !== null && userInfluencerStore.id > 0)
      return 'influencer'
    return null
  })

  const IS_LOGGED_IN = computed(() => {
    return id.value > 0
  })

  const IS_BAND = computed(() => {
    return IS_LOGGED_IN.value && KIND.value === 'band'
  })

  const IS_INF = computed(() => {
    return IS_LOGGED_IN.value && KIND.value === 'influencer'
  })

  const IS_NEW = computed(() => {
    return IS_BAND.value && !submissions.value.some((el) => el > 0)
  })

  const IS_ADMIN = computed(() => {
    return is_staff.value
  })

  const IS_AGENCY = computed(() => {
    if (KIND.value === 'band') return userAgencyStore.kind === 'Pr'
    else return false
  })

  const IS_INCOMPLETE_USER = computed(() => {
    if (!IS_LOGGED_IN.value || is_staff.value || loginStore.loading)
      return false

    if (IS_INF.value) {
      const hasCuratorFinishedOnboarding =
        userInfluencerStore.has_profile_picture
      return !hasCuratorFinishedOnboarding
    }

    if (IS_BAND.value) return userBandStore.IS_BAND_INCOMPLETE

    return true
  })

  const HAS_MULTI_BAND = computed(() => {
    return IS_BAND.value && IS_AGENCY.value && userBandSetStore.list.length > 1
  })

  const HAS_SUBMISSIONS = computed(() => {
    return !primitiveArraysAreEquals(
      arrayUniqueValues(submissions.value),
      arrayUniqueValues(initialSubmissions()),
    )
  })

  const HAS_PAID = computed(() => {
    return !!first_payment_date.value
  })

  return {
    // state
    email,
    first_name,
    first_payment_date,
    grooviz,
    id,
    is_active,
    is_facebook,
    is_google,
    is_staff,
    is_verified_mail,
    lang,
    last_name,
    picture,
    submissions,
    google_token,
    is_soundcloud,
    soundcloud_code,
    soundcloud_token,

    // actions
    RESET,
    SET,
    SET_GROOVIZ,
    SET_WALLET,
    LOAD,
    GET_USER_SUB_COUNT,
    GET_USER_WALLET,
    LOGOUT,
    UPDATE_GROOVIZ,

    // getters
    HAS_MULTI_BAND,
    HAS_PAID,
    HAS_SUBMISSIONS,
    IS_ADMIN,
    IS_AGENCY,
    IS_BAND,
    IS_INCOMPLETE_USER,
    IS_INF,
    IS_LOGGED_IN,
    IS_NEW,
    KIND,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
