import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftStore } from './draft'
import { useInfluencersStore } from './influencers'
import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { configToQs } from '~/helpers/configToQs'

import { provideFetchRecommendation } from '~/api-core/Recommendation/FetchRecommendation'

import type Tag from '~/entities/tag'
import type {
  ComputedMismatch,
  InsightsMismatch,
  MismatchType,
} from '~/types/influencerRecommendation'
import type InfluencerRecommendation from '~/types/influencerRecommendation'

const initialState = () => ({
  mismatches: {} as Record<number, InsightsMismatch[]>,
  list: {} as Record<number, number | undefined>,
  /**
   * ranks = influencer_ids ordered by rank
   */
  ranks: [] as number[],
  topInfluencerCount: 0,
})

const state = initialState

export type InfluencersRecommendationsState = ReturnType<typeof state>

// Exporting only for test as of 25.12.21
export interface ApiResponse {
  top_influencer_count: number
  recommendations: InfluencerRecommendation[]
}

export const useInfluencersRecommendationsStore = defineStore(
  'influencersRecommendations',
  () => {
    const { coreFetch } = useProvideCoreFetch()
    const { $pinia } = useNuxtApp()
    const userStore = useUserStore($pinia)
    const userBandStore = useUserBandStore($pinia)
    const draftStore = useDraftStore($pinia)
    const influencersStore = useInfluencersStore($pinia)
    const tagStore = useTagStore($pinia)
    const userFavoritesStore = useUserFavoritesStore($pinia)
    const influencersDuplicatesStore = useInfluencersDuplicatesStore($pinia)

    // state
    const mismatches = ref<Record<number, InsightsMismatch[]>>({})
    const list = ref<Record<number, number | undefined>>({})
    const ranks = ref<number[]>([])
    const topInfluencerCount = ref(0)

    // getters
    const TOP_INFLUENCER_IDS = computed(() => {
      return ranks.value.slice(0, topInfluencerCount.value)
    })

    const REMAINING_INFLUENCERS_IDS = computed(() => {
      return ranks.value.slice(topInfluencerCount.value)
    })

    // actions
    function RESET() {
      mismatches.value = initialState().mismatches
      list.value = initialState().list
      ranks.value = initialState().ranks
      topInfluencerCount.value = initialState().topInfluencerCount
    }

    function SET_RANKS(payload: number[]) {
      ranks.value = payload
    }

    function SET_LIST(payload: InfluencerRecommendation[]) {
      ranks.value = payload.map((e) => e.influencer_id)
      list.value = payload.reduce(
        (accumulator, recommendation) => {
          accumulator[recommendation.influencer_id] =
            recommendation.prediction_value
          return accumulator
        },
        {} as Record<number, number>,
      )
      mismatches.value = payload.reduce(
        (accumulator, { influencer_id: influencerId, insights }) => {
          if (insights?.mismatches)
            accumulator[influencerId] = insights.mismatches

          return accumulator
        },
        {} as Record<number, InsightsMismatch[]>,
      )
    }

    function MERGE_LIST(payload: InfluencerRecommendation[]) {
      payload.forEach(
        ({
          influencer_id: influencerId,
          insights,
          prediction_value: predictionValue,
        }) => {
          list.value[influencerId] = predictionValue
          // TODO: check this
          if (insights) mismatches.value[influencerId] = insights.mismatches
          else if (mismatches.value[influencerId])
            delete mismatches.value[influencerId]
        },
      )
    }

    function SET_TOP_INFLUENCER_COUNT(payload: number) {
      topInfluencerCount.value = payload
    }

    function FETCH_FROM_INFLUENCER_IDS(influencerIds: number[]): Promise<void> {
      return new Promise((resolve, reject) => {
        if (!userStore.IS_BAND) return resolve()

        const filteredSet: number[] = influencerIds.filter((id: number) => {
          return list.value[id] === undefined
        })

        if (!filteredSet.length) return resolve()

        Promise.all(
          filteredSet
            .reduce(
              (accumulator, influencerId) => {
                const lastMember: number[] = accumulator[accumulator.length - 1]

                if (lastMember.length >= 128) accumulator.push([] as number[])

                accumulator[accumulator.length - 1].push(influencerId)
                return accumulator
              },
              [[]] as number[][],
            )
            .map((influencerIdBatch) => {
              return coreFetch.$get(
                `/recommendation/influencers_sorted_by_reco/?${configToQs({
                  band_id: userBandStore.id,
                  influencer_ids: influencerIdBatch.join(','),
                })}`,
                { timeout: 2500 },
              )
            }),
        )
          .then((responseBatch: ApiResponse[]) => {
            const response = responseBatch.reduce(
              (accumulator, response) => {
                accumulator.recommendations.push(...response.recommendations)
                accumulator.top_influencer_count = response.top_influencer_count
                return accumulator
              },
              {
                recommendations: [],
                top_influencer_count: 0,
              } as ApiResponse,
            )

            MERGE_LIST(
              response.recommendations.map(
                ({
                  influencer_id: influencerId,
                  insights,
                  prediction_value: predictionValue,
                }) => ({
                  influencer_id: influencerId,
                  insights,
                  prediction_value: predictionValue,
                }),
              ),
            )
            resolve()
          })
          .catch(reject)
      })
    }

    /**
     * Fetches influencer recommendations from the reco service and sets them.
     *
     * NOTE: Gets called in FETCH_FROM_RECO 'influencersStore' action.
     */
    function FETCH_RECOMMENDATION(): Promise<void> {
      const draftId: number | undefined = draftStore.id || undefined
      const bandId: number = userBandStore.id

      if (!bandId) return Promise.resolve()

      const fetchRecommendation = provideFetchRecommendation(coreFetch)
      return fetchRecommendation(bandId, draftId).then((response) => {
        SET_LIST(
          response.recommendations.map(
            ({
              influencer_id: influencerId,
              insights,
              prediction_value: predictionValue,
            }) => ({
              influencer_id: influencerId,
              insights,
              prediction_value: predictionValue,
            }),
          ),
        )
        SET_TOP_INFLUENCER_COUNT(response.top_influencer_count)
      })
    }

    function GET_BY_INFLUENCER_ID(id: number) {
      return list.value[id]
    }

    function INFLUENCER_ID_IS_IN_TOP(influencerId: number) {
      return TOP_INFLUENCER_IDS.value.includes(influencerId)
    }

    function GET_MISMATCHES_BY_INFLUENCER_ID(influencerId: number) {
      const influencerMismatches = mismatches.value?.[influencerId] ?? null
      const GET_TAGS_FROM_IDS = tagStore.GET_TAGS_FROM_IDS
      const influencer = influencersStore.GET_BY_ID(influencerId)

      return (
        influencerMismatches &&
        influencerMismatches.reduce(
          (acc, { type, weak }) => {
            if (!influencer) return acc

            switch (type) {
              case 'country': {
                const {
                  tags: {
                    exclusivity: { country: exclusiveCountries },
                  },
                } = influencer

                acc.country = {
                  tags: GET_TAGS_FROM_IDS(exclusiveCountries ?? []) as Tag[],
                  weak,
                }
                break
              }
              case 'lyrics_lang': {
                const {
                  tags: {
                    exclusivity: { lyrics_lang: exclusiveLyricsLang },
                  },
                } = influencer
                acc.lyrics_lang = {
                  tags: GET_TAGS_FROM_IDS(exclusiveLyricsLang ?? []) as Tag[],
                  weak,
                }
                break
              }
              case 'track_age': {
                const {
                  tags: {
                    exclusivity: { track_age: exclusiveTrackAge },
                  },
                } = influencer
                if (exclusiveTrackAge) {
                  acc.track_age = {
                    tags: GET_TAGS_FROM_IDS(exclusiveTrackAge ?? []) as Tag[],
                    weak,
                  }
                }

                break
              }
            }
            return acc
          },
          {} as Record<MismatchType, ComputedMismatch>,
        )
      )
    }

    return {
      // state
      mismatches,
      list,
      ranks,
      topInfluencerCount,

      // getters
      REMAINING_INFLUENCERS_IDS,
      TOP_INFLUENCER_IDS,

      // actions
      FETCH_FROM_INFLUENCER_IDS,
      FETCH_RECOMMENDATION,
      GET_BY_INFLUENCER_ID,
      GET_MISMATCHES_BY_INFLUENCER_ID,
      INFLUENCER_ID_IS_IN_TOP,
      MERGE_LIST,
      RESET,
      SET_RANKS,
      SET_LIST,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencersRecommendationsStore, import.meta.hot),
  )
}
