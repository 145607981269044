import type { APICart, APICartCheckout } from '~/types/cart'
import type { CoreFetch } from '~/types/CoreFetch'

export function provideGetCart(coreFetch: CoreFetch) {
  return async (cartId: number) =>
    await coreFetch.$get<APICart>(`/cart/cart/${cartId}/`)
}

export function provideGetDraftCart(coreFetch: CoreFetch) {
  return async (draftId: number) =>
    await coreFetch.$get<APICart>(`/submission/draft/${draftId}/cart/`)
}
export function provideGetPackCart(coreFetch: CoreFetch) {
  return async (packId: number, bandId: number) =>
    await coreFetch.$post<APICart>(`/wallet/pack/${packId}/cart/`, {
      band_id: bandId,
    })
}

export function provideGetCartCheckout(coreFetch: CoreFetch) {
  return async (cartId: number) =>
    await coreFetch.$get<APICartCheckout>(`/cart/cart/${cartId}/checkout/`)
}
