import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { usePayinPaymentMethodCardsStore } from './payinPaymentMethodCards'
import { usePayinPaymentMethodPaypalStore } from './payinPaymentMethodPaypal'

import type { AcceptablePayinOrigins } from './payin'

// const definition only used in jest testing
export const availablePaymentMethods = [
  'paypal',
  'create_card',
  'card',
] as const

export type AvailablePaymentMethod = (typeof availablePaymentMethods)[number]

export interface PaymentData<
  T extends AcceptablePayinOrigins = AcceptablePayinOrigins,
> {
  kind?: T
  cart: number
  legal_data: number
  user: number
}

export const usePayinPaymentMethodStore = defineStore(
  'payinPaymentMethod',
  () => {
    const { $pinia } = useNuxtApp()

    const payinPaymentMethodCardsStore = usePayinPaymentMethodCardsStore($pinia)
    const payinPaymentMethodPaypalStore =
      usePayinPaymentMethodPaypalStore($pinia)

    const selected = ref<AvailablePaymentMethod | ''>('')

    // getters

    const IS_PAYPAL = computed(() => {
      return selected.value === 'paypal'
    })

    // actions

    function SET_SELECTED(newSelected: AvailablePaymentMethod | '') {
      selected.value = newSelected
    }

    async function FETCH() {
      await payinPaymentMethodCardsStore.FETCH()
    }

    function RESET() {
      SET_SELECTED('')
      payinPaymentMethodCardsStore.SET_AVAILABLE([])
      payinPaymentMethodPaypalStore.RESET()
    }

    return {
      // state
      selected,

      // getters
      IS_PAYPAL,

      // actions
      SET_SELECTED,
      FETCH,
      RESET,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinPaymentMethodStore, import.meta.hot),
  )
}
