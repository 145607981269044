
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta } from "/root/front/pages/lp/[slug].vue?macro=true";
import { default as aboutohmgj2GwANVFEqL_nnTg_0C2wSVDRg8xszluhW2t30AMeta } from "/root/front/pages/about.vue?macro=true";
import { default as _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta } from "/root/front/pages/agency/favorites/[id].vue?macro=true";
import { default as indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta } from "/root/front/pages/agency/favorites/index.vue?macro=true";
import { default as create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta } from "/root/front/pages/band/create-draft.vue?macro=true";
import { default as emailsAzQ_45m9QaNiSQ8rmlHWrSmal1v_9SWrJroQcS_5r09FQMeta } from "/root/front/pages/band/dashboard/emails.vue?macro=true";
import { default as indexJpBXFD2Gj_45k2ipHO59jg6aFTIYAR2KRlev2JgW67coYMeta } from "/root/front/pages/band/dashboard/index.vue?macro=true";
import { default as dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta } from "/root/front/pages/band/dashboard.vue?macro=true";
import { default as index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta } from "/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue?macro=true";
import { default as index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta } from "/root/front/pages/band/edit/track/[id]/index.vue?macro=true";
import { default as homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta } from "/root/front/pages/band/homepage.vue?macro=true";
import { default as onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta } from "/root/front/pages/band/onboarding.vue?macro=true";
import { default as parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta } from "/root/front/pages/band/parameter.vue?macro=true";
import { default as payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta } from "/root/front/pages/band/payin.vue?macro=true";
import { default as edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta } from "/root/front/pages/band/profile/[slug]/edit.vue?macro=true";
import { default as indexUrAZr_459rn0RqFikSSXvZ8fZ7CnrmcIvS4q8H2r5hRYcMeta } from "/root/front/pages/band/profile/[slug]/index.vue?macro=true";
import { default as createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta } from "/root/front/pages/band/profile/create.vue?macro=true";
import { default as deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta } from "/root/front/pages/band/profile/delete.vue?macro=true";
import { default as indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta } from "/root/front/pages/band/profile/index.vue?macro=true";
import { default as selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta } from "/root/front/pages/band/profile/select.vue?macro=true";
import { default as _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta } from "/root/front/pages/band/referral/[code].vue?macro=true";
import { default as indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta } from "/root/front/pages/band/referral/index.vue?macro=true";
import { default as sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta } from "/root/front/pages/band/sendtrack.vue?macro=true";
import { default as finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta } from "/root/front/pages/band/shop/finalized.vue?macro=true";
import { default as indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta } from "/root/front/pages/band/shop/index.vue?macro=true";
import { default as stripeAlIanRbyyx2wtgjvXKq0Lx29hZSjaWATnKStuOoWYsEMeta } from "/root/front/pages/band/shop/processing/stripe.vue?macro=true";
import { default as discoverypeFYLEGHskhmrnxRDHNi6yp1GrCMqTZcnnQwyZuFepwMeta } from "/root/front/pages/band/signup/discovery.vue?macro=true";
import { default as indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta } from "/root/front/pages/band/signup/index.vue?macro=true";
import { default as _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta } from "/root/front/pages/band/signup/referral/agency/[code].vue?macro=true";
import { default as _91code_93W_4590c1SHfczzPJZkcHRuY5dh5e73Z5Df2XLhBI2GFvUMeta } from "/root/front/pages/band/signup/referral/influencer/[code].vue?macro=true";
import { default as cgsaBL3mSrodXIF591byw6TWm5js_Bsb1KcZiQGt9p2LRgMeta } from "/root/front/pages/cgs.vue?macro=true";
import { default as _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta } from "/root/front/pages/charts/[...slug].vue?macro=true";
import { default as contactsLMzq03xn4fy6rj_4539D6yUriSvbeBvomZUrXG0hg6mgMeta } from "/root/front/pages/contact.vue?macro=true";
import { default as continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta } from "/root/front/pages/continue-signup.vue?macro=true";
import { default as finalizedDQiya9swW6gPwxzNOueEuuMnlTqL_FVr79eyFksBUuYMeta } from "/root/front/pages/draft/[id]/finalized.vue?macro=true";
import { default as messagesI0by_Mljvorri77UvQWcsjjIL2zSBkdzd8_Te4ARO34Meta } from "/root/front/pages/draft/[id]/index/messages.vue?macro=true";
import { default as recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta } from "/root/front/pages/draft/[id]/index/recap.vue?macro=true";
import { default as shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta } from "/root/front/pages/draft/[id]/index/shared-music.vue?macro=true";
import { default as trackhJ5NoQIHly9f1jwQJKY78VNA5RDobKnzGmQLH0grj8AMeta } from "/root/front/pages/draft/[id]/index/track.vue?macro=true";
import { default as indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta } from "/root/front/pages/draft/[id]/index.vue?macro=true";
import { default as indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta } from "/root/front/pages/draft/[id]/influencers/index.vue?macro=true";
import { default as lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta } from "/root/front/pages/draft/[id]/influencers/lists.vue?macro=true";
import { default as loadingEPis4j5T7CQwykszEoZfLyDUSshEew1piZDhsSEPAMgMeta } from "/root/front/pages/draft/[id]/influencers/loading.vue?macro=true";
import { default as influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta } from "/root/front/pages/draft/[id]/influencers.vue?macro=true";
import { default as post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta } from "/root/front/pages/draft/[id]/post3ds.vue?macro=true";
import { default as stripe8uSWZz_Y8ajdpnuwdj_45YSq786sB5aSOfrZJwXmWJXMQMeta } from "/root/front/pages/draft/[id]/processing/stripe.vue?macro=true";
import { default as indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta } from "/root/front/pages/draft/index.vue?macro=true";
import { default as influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta } from "/root/front/pages/draft/influencers.vue?macro=true";
import { default as finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta } from "/root/front/pages/finalize-auth.vue?macro=true";
import { default as activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta } from "/root/front/pages/influencer/activate.vue?macro=true";
import { default as cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta } from "/root/front/pages/influencer/cashout.vue?macro=true";
import { default as dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta } from "/root/front/pages/influencer/dashboard.vue?macro=true";
import { default as indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta } from "/root/front/pages/influencer/list/all/index.vue?macro=true";
import { default as fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta } from "/root/front/pages/influencer/list/fans.vue?macro=true";
import { default as new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta } from "/root/front/pages/influencer/list/new-arrivals.vue?macro=true";
import { default as popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta } from "/root/front/pages/influencer/list/popular.vue?macro=true";
import { default as _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta } from "/root/front/pages/influencer/list/tags/[...slug].vue?macro=true";
import { default as parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta } from "/root/front/pages/influencer/parameter.vue?macro=true";
import { default as editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta } from "/root/front/pages/influencer/profile/[slug]/edit.vue?macro=true";
import { default as indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta } from "/root/front/pages/influencer/profile/[slug]/index.vue?macro=true";
import { default as indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta } from "/root/front/pages/influencer/profile/index.vue?macro=true";
import { default as discoveryhlDJoXO0f8sPn_45To9BtNRje6LSvZ7bWy4_45w_bFuqxK4Meta } from "/root/front/pages/influencer/signup/discovery.vue?macro=true";
import { default as indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta } from "/root/front/pages/influencer/signup/index.vue?macro=true";
import { default as biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta } from "/root/front/pages/influencer/signup/v2/bio.vue?macro=true";
import { default as genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta } from "/root/front/pages/influencer/signup/v2/genres.vue?macro=true";
import { default as lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta } from "/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue?macro=true";
import { default as name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta } from "/root/front/pages/influencer/signup/v2/name-country.vue?macro=true";
import { default as opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta } from "/root/front/pages/influencer/signup/v2/opportunities.vue?macro=true";
import { default as picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta } from "/root/front/pages/influencer/signup/v2/picture.vue?macro=true";
import { default as similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta } from "/root/front/pages/influencer/signup/v2/similar-artists.vue?macro=true";
import { default as typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta } from "/root/front/pages/influencer/signup/v2/type.vue?macro=true";
import { default as website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta } from "/root/front/pages/influencer/signup/v2/website-links.vue?macro=true";
import { default as index_2K_6HOgM8pX8LBv5bCpATClnPc_45fQN4e68a6H4je_kMeta } from "/root/front/pages/influencer/wallet/index.vue?macro=true";
import { default as transfereUFxAfb1rLgFtRbrneHs5fFj6Sa3Bm5VPfXyH4sLevAMeta } from "/root/front/pages/influencer/wallet/transfer.vue?macro=true";
import { default as withdrawHyLZb8DeeZS0OgwVfBExGgrW8WcYOiOZb_NzUS2SQ7sMeta } from "/root/front/pages/influencer/wallet/withdraw.vue?macro=true";
import { default as walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta } from "/root/front/pages/influencer/wallet.vue?macro=true";
import { default as _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta } from "/root/front/pages/influencer/widget/[slug].vue?macro=true";
import { default as indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta } from "/root/front/pages/influencer/widget/index.vue?macro=true";
import { default as _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta } from "/root/front/pages/influencer/widget/redirect/[slug].vue?macro=true";
import { default as partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta } from "/root/front/pages/partners.vue?macro=true";
import { default as failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta } from "/root/front/pages/payin/failure.vue?macro=true";
import { default as retryf_45cw7c58YRzA30NIFRGAG0adxAmAS95PDFWjRz4yfjMMeta } from "/root/front/pages/payin/paypal/retry.vue?macro=true";
import { default as purgeeagBihF6r6DEzitJK1eS36HsJ2qGgfXpard4pGr6XGgMeta } from "/root/front/pages/purge.vue?macro=true";
import { default as reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta } from "/root/front/pages/reset-password.vue?macro=true";
import { default as signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta } from "/root/front/pages/signup.vue?macro=true";
import { default as indexssCXkEPLPEEiuM5yVzY_45fBCW0xnGUvFRpOWnyBdIn2MMeta } from "/root/front/pages/spotify/playlist/[id]/index.vue?macro=true";
import { default as loadingmIrdO_TSb0LHiM8Vrat2yGv_EAh7ec4ureLUob_bOoMMeta } from "/root/front/pages/spotify/playlist/[id]/loading.vue?macro=true";
import { default as trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta } from "/root/front/pages/spotify/share/track.vue?macro=true";
import { default as testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta } from "/root/front/pages/test.vue?macro=true";
import { default as _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta } from "/root/front/pages/tools/[...slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___en",
    path: "/en/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___fr",
    path: "/fr/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___de",
    path: "/de/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___es",
    path: "/es/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___pt",
    path: "/pt/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___it",
    path: "/it/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "about___en",
    path: "/en/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___de",
    path: "/de/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___es",
    path: "/es/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___pt",
    path: "/pt/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___it",
    path: "/it/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "agency-favorites-id___en",
    path: "/en/agency/favorites/:id()/",
    meta: _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___fr",
    path: "/fr/agency/favorites/:id()/",
    meta: _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___de",
    path: "/de/agency/favorites/:id()/",
    meta: _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___es",
    path: "/es/agency/favorites/:id()/",
    meta: _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___pt",
    path: "/pt/agency/favorites/:id()/",
    meta: _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___it",
    path: "/it/agency/favorites/:id()/",
    meta: _91id_93Dy1RwXzixVA_gcFU_cfyz9u_45to3wf0FRBuQQJxdvYRUMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites___en",
    path: "/en/agency/favorites/",
    meta: indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___fr",
    path: "/fr/agency/favorites/",
    meta: indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___de",
    path: "/de/agency/favorites/",
    meta: indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___es",
    path: "/es/agency/favorites/",
    meta: indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___pt",
    path: "/pt/agency/favorites/",
    meta: indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___it",
    path: "/it/agency/favorites/",
    meta: indexNGWSPhpGvj5VJuCVPpQxEPfcDlToYCMG61BWUadDRvcMeta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "band-create-draft___en",
    path: "/en/band/create-draft/",
    meta: create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___fr",
    path: "/fr/band/create-draft/",
    meta: create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___de",
    path: "/de/band/create-draft/",
    meta: create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___es",
    path: "/es/band/create-draft/",
    meta: create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___pt",
    path: "/pt/band/create-draft/",
    meta: create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___it",
    path: "/it/band/create-draft/",
    meta: create_45draftO5fjUNeCXhUC9idDvobeVnlCljrLXl9fTbHfOlSn7lIMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta?.name,
    path: "/en/band/dashboard/",
    meta: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___en",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___en",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta?.name,
    path: "/fr/band/dashboard/",
    meta: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___fr",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___fr",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta?.name,
    path: "/de/band/dashboard/",
    meta: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___de",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___de",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta?.name,
    path: "/es/band/dashboard/",
    meta: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___es",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___es",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta?.name,
    path: "/pt/band/dashboard/",
    meta: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___pt",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___pt",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta?.name,
    path: "/it/band/dashboard/",
    meta: dashboardMherDNHvGBFerJfTY3PKCDNKV9matPrkuJLZSiA7gsQMeta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___it",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___it",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: "band-edit-track-id-campaign-campaignId___en",
    path: "/en/band/edit/track/:id()/campaign/:campaignId()/",
    meta: index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___fr",
    path: "/fr/band/edit/track/:id()/campaign/:campaignId()/",
    meta: index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___de",
    path: "/de/band/edit/track/:id()/campaign/:campaignId()/",
    meta: index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___es",
    path: "/es/band/edit/track/:id()/campaign/:campaignId()/",
    meta: index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___pt",
    path: "/pt/band/edit/track/:id()/campaign/:campaignId()/",
    meta: index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___it",
    path: "/it/band/edit/track/:id()/campaign/:campaignId()/",
    meta: index_45ez9aNboBt_45wNxAwIU4NFwiK0rP4p5peCauAQEXJsfQMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id___en",
    path: "/en/band/edit/track/:id()/",
    meta: index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___fr",
    path: "/fr/band/edit/track/:id()/",
    meta: index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___de",
    path: "/de/band/edit/track/:id()/",
    meta: index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___es",
    path: "/es/band/edit/track/:id()/",
    meta: index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___pt",
    path: "/pt/band/edit/track/:id()/",
    meta: index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___it",
    path: "/it/band/edit/track/:id()/",
    meta: index8hTonMYIV0dzdiussaiyY6_lZk9NOe_458dNQeL1qZangMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-homepage___en",
    path: "/en/band/homepage/",
    meta: homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___fr",
    path: "/fr/band/homepage/",
    meta: homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___de",
    path: "/de/band/homepage/",
    meta: homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___es",
    path: "/es/band/homepage/",
    meta: homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___pt",
    path: "/pt/band/homepage/",
    meta: homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___it",
    path: "/it/band/homepage/",
    meta: homepagetKaNsgDAFJOEJn_PTW1cR4VB0rS8O2wfMFoYABTKzAkMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-onboarding___en",
    path: "/en/band/onboarding/",
    meta: onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___fr",
    path: "/fr/band/onboarding/",
    meta: onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___de",
    path: "/de/band/onboarding/",
    meta: onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___es",
    path: "/es/band/onboarding/",
    meta: onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___pt",
    path: "/pt/band/onboarding/",
    meta: onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___it",
    path: "/it/band/onboarding/",
    meta: onboardingIbdL9ko1ErxOfMT9Dif8DiQKb5ik7SucgRTlrDh3ERwMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-parameter___en",
    path: "/en/band/parameter/",
    meta: parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___fr",
    path: "/fr/band/parameter/",
    meta: parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___de",
    path: "/de/band/parameter/",
    meta: parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___es",
    path: "/es/band/parameter/",
    meta: parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___pt",
    path: "/pt/band/parameter/",
    meta: parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___it",
    path: "/it/band/parameter/",
    meta: parameter02P0ECtZ1FZdAwpli9j3GekgKyHeufmtnhbl4GFaJAAMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-payin___en",
    path: "/en/band/payin/",
    meta: payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___fr",
    path: "/fr/band/payin/",
    meta: payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___de",
    path: "/de/band/payin/",
    meta: payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___es",
    path: "/es/band/payin/",
    meta: payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___pt",
    path: "/pt/band/payin/",
    meta: payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___it",
    path: "/it/band/payin/",
    meta: payinAR_YMBB_6TCiqxS9VvPPunBP5IqNnUuudKl5rvd1mWQMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-profile-slug-edit___en",
    path: "/en/band/profile/:slug()/edit/",
    meta: edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___fr",
    path: "/fr/band/profile/:slug()/edit/",
    meta: edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___de",
    path: "/de/band/profile/:slug()/edit/",
    meta: edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___es",
    path: "/es/band/profile/:slug()/edit/",
    meta: edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___pt",
    path: "/pt/band/profile/:slug()/edit/",
    meta: edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___it",
    path: "/it/band/profile/:slug()/edit/",
    meta: edit0xPyUTGnDaqEtCOVW5_q2TXr924164Ajx2A9orpJA_45YMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug___en",
    path: "/en/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___fr",
    path: "/fr/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___de",
    path: "/de/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___es",
    path: "/es/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___pt",
    path: "/pt/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___it",
    path: "/it/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-create___en",
    path: "/en/band/profile/create/",
    meta: createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___fr",
    path: "/fr/band/profile/create/",
    meta: createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___de",
    path: "/de/band/profile/create/",
    meta: createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___es",
    path: "/es/band/profile/create/",
    meta: createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___pt",
    path: "/pt/band/profile/create/",
    meta: createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___it",
    path: "/it/band/profile/create/",
    meta: createlHYjpyL60kXHx_fV1GkPGPEkAAOJdTV6_1aVuw64ZHYMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-delete___en",
    path: "/en/band/profile/delete/",
    meta: deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___fr",
    path: "/fr/band/profile/delete/",
    meta: deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___de",
    path: "/de/band/profile/delete/",
    meta: deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___es",
    path: "/es/band/profile/delete/",
    meta: deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___pt",
    path: "/pt/band/profile/delete/",
    meta: deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___it",
    path: "/it/band/profile/delete/",
    meta: deleteLWUKXwppIxrgnuDZB7LImH7Yd_45OOaHqWRZrT71DSQjgMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile___en",
    path: "/en/band/profile/",
    meta: indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___fr",
    path: "/fr/band/profile/",
    meta: indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___de",
    path: "/de/band/profile/",
    meta: indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___es",
    path: "/es/band/profile/",
    meta: indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___pt",
    path: "/pt/band/profile/",
    meta: indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___it",
    path: "/it/band/profile/",
    meta: indexpZFaZ_IzWtNVBcExBnhMzpTTfyw4OYb67dKwd7eNkFcMeta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile-select___en",
    path: "/en/band/profile/select/",
    meta: selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___fr",
    path: "/fr/band/profile/select/",
    meta: selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___de",
    path: "/de/band/profile/select/",
    meta: selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___es",
    path: "/es/band/profile/select/",
    meta: selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___pt",
    path: "/pt/band/profile/select/",
    meta: selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___it",
    path: "/it/band/profile/select/",
    meta: selectuSOHsr_45kjNolZ1md2upup2Py7RDq_45oiYuqMcM3JP37IMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-referral-code___en",
    path: "/en/band/referral/:code()/",
    meta: _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___fr",
    path: "/fr/band/referral/:code()/",
    meta: _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___de",
    path: "/de/band/referral/:code()/",
    meta: _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___es",
    path: "/es/band/referral/:code()/",
    meta: _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___pt",
    path: "/pt/band/referral/:code()/",
    meta: _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___it",
    path: "/it/band/referral/:code()/",
    meta: _91code_93Qi_nx3BG1Cpf3_4rdDBPYhSeIv8kmVYHoM6yLT7jyUwMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral___en",
    path: "/en/band/referral/",
    meta: indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___fr",
    path: "/fr/band/referral/",
    meta: indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___de",
    path: "/de/band/referral/",
    meta: indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___es",
    path: "/es/band/referral/",
    meta: indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___pt",
    path: "/pt/band/referral/",
    meta: indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___it",
    path: "/it/band/referral/",
    meta: indexVGg_T9JcBKShTVSUHnRBnTePyne4LREPannEeVlW6DgMeta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-sendtrack___en",
    path: "/en/band/sendtrack/",
    meta: sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___fr",
    path: "/fr/band/sendtrack/",
    meta: sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___de",
    path: "/de/band/sendtrack/",
    meta: sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___es",
    path: "/es/band/sendtrack/",
    meta: sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___pt",
    path: "/pt/band/sendtrack/",
    meta: sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___it",
    path: "/it/band/sendtrack/",
    meta: sendtrackJeTB_tSAbsjcLvA7ovt9EMoYKnoU45sy2R0Nj4T_ehsMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-shop-finalized___en",
    path: "/en/band/shop/finalized/",
    meta: finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___fr",
    path: "/fr/band/shop/finalized/",
    meta: finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___de",
    path: "/de/band/shop/finalized/",
    meta: finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___es",
    path: "/es/band/shop/finalized/",
    meta: finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___pt",
    path: "/pt/band/shop/finalized/",
    meta: finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___it",
    path: "/it/band/shop/finalized/",
    meta: finalizedPcHq_45KdS_H6IX3ebrv3na9P3VSHqnzUsy3X5roBNgL4Meta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop___en",
    path: "/en/band/shop/",
    meta: indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___fr",
    path: "/fr/band/shop/",
    meta: indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___de",
    path: "/de/band/shop/",
    meta: indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___es",
    path: "/es/band/shop/",
    meta: indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___pt",
    path: "/pt/band/shop/",
    meta: indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___it",
    path: "/it/band/shop/",
    meta: indexRwR7WwfF_45FeSVJatHYZH8uGZqh4YAW8VFhGDhkU0_45RoMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop-processing-stripe___en",
    path: "/en/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___fr",
    path: "/fr/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___de",
    path: "/de/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___es",
    path: "/es/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___pt",
    path: "/pt/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___it",
    path: "/it/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-signup-discovery___en",
    path: "/en/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___fr",
    path: "/fr/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___de",
    path: "/de/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___es",
    path: "/es/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___pt",
    path: "/pt/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___it",
    path: "/it/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup___en",
    path: "/en/band/signup/",
    meta: indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___fr",
    path: "/fr/band/signup/",
    meta: indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___de",
    path: "/de/band/signup/",
    meta: indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___es",
    path: "/es/band/signup/",
    meta: indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___pt",
    path: "/pt/band/signup/",
    meta: indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___it",
    path: "/it/band/signup/",
    meta: indexGt5bPp_45wbSkiO1cYaVVKkGkXtJdv2P5H4lQrnHyhjG8Meta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup-referral-agency-code___en",
    path: "/en/band/signup/referral/agency/:code()/",
    meta: _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___fr",
    path: "/fr/band/signup/referral/agency/:code()/",
    meta: _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___de",
    path: "/de/band/signup/referral/agency/:code()/",
    meta: _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___es",
    path: "/es/band/signup/referral/agency/:code()/",
    meta: _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___pt",
    path: "/pt/band/signup/referral/agency/:code()/",
    meta: _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___it",
    path: "/it/band/signup/referral/agency/:code()/",
    meta: _91code_93_SLVEUiGLg5LTt_TU175gkIGV0oYxVbkgcLk9o8_hLYMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___en",
    path: "/en/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___fr",
    path: "/fr/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___de",
    path: "/de/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___es",
    path: "/es/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___pt",
    path: "/pt/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___it",
    path: "/it/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "cgs___en",
    path: "/en/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___fr",
    path: "/fr/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___de",
    path: "/de/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___es",
    path: "/es/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___pt",
    path: "/pt/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___it",
    path: "/it/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "charts-slug___en",
    path: "/en/charts/:slug(.*)*/",
    meta: _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta || {},
    component: () => import("/root/front/pages/charts/[...slug].vue")
  },
  {
    name: "charts-slug___fr",
    path: "/fr/charts/:slug(.*)*/",
    meta: _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta || {},
    component: () => import("/root/front/pages/charts/[...slug].vue")
  },
  {
    name: "charts-slug___de",
    path: "/de/charts/:slug(.*)*/",
    meta: _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta || {},
    component: () => import("/root/front/pages/charts/[...slug].vue")
  },
  {
    name: "charts-slug___es",
    path: "/es/charts/:slug(.*)*/",
    meta: _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta || {},
    component: () => import("/root/front/pages/charts/[...slug].vue")
  },
  {
    name: "charts-slug___pt",
    path: "/pt/charts/:slug(.*)*/",
    meta: _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta || {},
    component: () => import("/root/front/pages/charts/[...slug].vue")
  },
  {
    name: "charts-slug___it",
    path: "/it/charts/:slug(.*)*/",
    meta: _91_46_46_46slug_931UbWmsFpWdtxD2uOIGodJ_450zHYj3saIFQBrvPU60eJUMeta || {},
    component: () => import("/root/front/pages/charts/[...slug].vue")
  },
  {
    name: "contact___en",
    path: "/en/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___pt",
    path: "/pt/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "continue-signup___en",
    path: "/en/continue-signup/",
    meta: continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta || {},
    component: () => import("/root/front/pages/continue-signup.vue")
  },
  {
    name: "continue-signup___fr",
    path: "/fr/continue-signup/",
    meta: continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta || {},
    component: () => import("/root/front/pages/continue-signup.vue")
  },
  {
    name: "continue-signup___de",
    path: "/de/continue-signup/",
    meta: continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta || {},
    component: () => import("/root/front/pages/continue-signup.vue")
  },
  {
    name: "continue-signup___es",
    path: "/es/continue-signup/",
    meta: continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta || {},
    component: () => import("/root/front/pages/continue-signup.vue")
  },
  {
    name: "continue-signup___pt",
    path: "/pt/continue-signup/",
    meta: continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta || {},
    component: () => import("/root/front/pages/continue-signup.vue")
  },
  {
    name: "continue-signup___it",
    path: "/it/continue-signup/",
    meta: continue_45signupoV0uI2DjjZqdsG89j3_VsTDA_4533FUW5TkYGu73fScgEMeta || {},
    component: () => import("/root/front/pages/continue-signup.vue")
  },
  {
    name: "draft-id-finalized___en",
    path: "/en/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___fr",
    path: "/fr/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___de",
    path: "/de/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___es",
    path: "/es/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___pt",
    path: "/pt/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___it",
    path: "/it/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id___en",
    path: "/en/draft/:id()/",
    meta: indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___en",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___en",
    path: "recap/",
    meta: recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___en",
    path: "shared-music/",
    meta: shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___en",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___fr",
    path: "/fr/draft/:id()/",
    meta: indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___fr",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___fr",
    path: "recap/",
    meta: recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___fr",
    path: "shared-music/",
    meta: shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___fr",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___de",
    path: "/de/draft/:id()/",
    meta: indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___de",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___de",
    path: "recap/",
    meta: recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___de",
    path: "shared-music/",
    meta: shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___de",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___es",
    path: "/es/draft/:id()/",
    meta: indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___es",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___es",
    path: "recap/",
    meta: recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___es",
    path: "shared-music/",
    meta: shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___es",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___pt",
    path: "/pt/draft/:id()/",
    meta: indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___pt",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___pt",
    path: "recap/",
    meta: recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___pt",
    path: "shared-music/",
    meta: shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___pt",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___it",
    path: "/it/draft/:id()/",
    meta: indexniekAxrnuUfjt5J0GvaQem2UvadwkJpooxvIG1Hzv48Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___it",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___it",
    path: "recap/",
    meta: recap7RYG75F0fdyfSw8nhqRIIndWBTq2iEer7DixHxuTp9wMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___it",
    path: "shared-music/",
    meta: shared_45musiclw_faKCfdgUkO6uRKcxNQKxRXvZUUd_45uV6u_L6hzc3AMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___it",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta?.name,
    path: "/en/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___en",
    path: "",
    meta: indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___en",
    path: "lists/",
    meta: lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___en",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta?.name,
    path: "/fr/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___fr",
    path: "",
    meta: indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___fr",
    path: "lists/",
    meta: lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___fr",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta?.name,
    path: "/de/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___de",
    path: "",
    meta: indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___de",
    path: "lists/",
    meta: lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___de",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta?.name,
    path: "/es/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___es",
    path: "",
    meta: indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___es",
    path: "lists/",
    meta: lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___es",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta?.name,
    path: "/pt/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___pt",
    path: "",
    meta: indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___pt",
    path: "lists/",
    meta: lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___pt",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersgAiZz5_KblJbHahV_Yr_45wJOGttTYTMBnucTLgu1pShAMeta?.name,
    path: "/it/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___it",
    path: "",
    meta: indexeADiFjBdi2ibhd01iv8Skd0erXyHuk2KEBZCZNvetEQMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___it",
    path: "lists/",
    meta: lists48n7qe4R57ol4UoaCs2Axv8EM0HIbT8G8lqErONiowMMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___it",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: "draft-id-post3ds___en",
    path: "/en/draft/:id()/post3ds/",
    meta: post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___fr",
    path: "/fr/draft/:id()/post3ds/",
    meta: post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___de",
    path: "/de/draft/:id()/post3ds/",
    meta: post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___es",
    path: "/es/draft/:id()/post3ds/",
    meta: post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___pt",
    path: "/pt/draft/:id()/post3ds/",
    meta: post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___it",
    path: "/it/draft/:id()/post3ds/",
    meta: post3dsn6RrVxQ9i0KlLjDLR364YB6qAnjwfTk2mdIUE49PDegMeta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-processing-stripe___en",
    path: "/en/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___fr",
    path: "/fr/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___de",
    path: "/de/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___es",
    path: "/es/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___pt",
    path: "/pt/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___it",
    path: "/it/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft___en",
    path: "/en/draft/",
    meta: indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___fr",
    path: "/fr/draft/",
    meta: indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___de",
    path: "/de/draft/",
    meta: indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___es",
    path: "/es/draft/",
    meta: indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___pt",
    path: "/pt/draft/",
    meta: indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___it",
    path: "/it/draft/",
    meta: indexJd1ZLMq8_45Cw4Pl5COOeJJvHVDY93eR4fp9fiaPTPgCUMeta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft-influencers___en",
    path: "/en/draft/influencers/",
    meta: influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___fr",
    path: "/fr/draft/influencers/",
    meta: influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___de",
    path: "/de/draft/influencers/",
    meta: influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___es",
    path: "/es/draft/influencers/",
    meta: influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___pt",
    path: "/pt/draft/influencers/",
    meta: influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___it",
    path: "/it/draft/influencers/",
    meta: influencers4a7sCYI455vg5aL8Qtz4vCbjGg_45xZxp4uOdqXuTwRC8Meta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "finalize-auth___en",
    path: "/en/finalize-auth/",
    meta: finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___fr",
    path: "/fr/finalize-auth/",
    meta: finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___de",
    path: "/de/finalize-auth/",
    meta: finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___es",
    path: "/es/finalize-auth/",
    meta: finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___pt",
    path: "/pt/finalize-auth/",
    meta: finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___it",
    path: "/it/finalize-auth/",
    meta: finalize_45authAGMzmynCiqfvR0jpq9VFI2fccmS9tv6AgQTqrM6nN_45oMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "influencer-activate___en",
    path: "/en/influencer/activate/",
    meta: activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___fr",
    path: "/fr/influencer/activate/",
    meta: activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___de",
    path: "/de/influencer/activate/",
    meta: activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___es",
    path: "/es/influencer/activate/",
    meta: activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___pt",
    path: "/pt/influencer/activate/",
    meta: activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___it",
    path: "/it/influencer/activate/",
    meta: activateF6NJogR5xfCuPV_45UcNTmSJBeUPvOPyXGAmrEajqKxtgMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-cashout___en",
    path: "/en/influencer/cashout/",
    meta: cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___fr",
    path: "/fr/influencer/cashout/",
    meta: cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___de",
    path: "/de/influencer/cashout/",
    meta: cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___es",
    path: "/es/influencer/cashout/",
    meta: cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___pt",
    path: "/pt/influencer/cashout/",
    meta: cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___it",
    path: "/it/influencer/cashout/",
    meta: cashoutPJNpjPhcIBOSysfLXtA1FbE46ydXiNPsre0354tP1yoMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-dashboard___en",
    path: "/en/influencer/dashboard/",
    meta: dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___fr",
    path: "/fr/influencer/dashboard/",
    meta: dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___de",
    path: "/de/influencer/dashboard/",
    meta: dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___es",
    path: "/es/influencer/dashboard/",
    meta: dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___pt",
    path: "/pt/influencer/dashboard/",
    meta: dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___it",
    path: "/it/influencer/dashboard/",
    meta: dashboardwzA5Ys_45W1fVXULMXM58qpIBfshGrvIDTuEJJvT5MODAMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-list-all___en",
    path: "/en/influencer/list/all/",
    meta: indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___fr",
    path: "/fr/influencer/list/all/",
    meta: indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___de",
    path: "/de/influencer/list/all/",
    meta: indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___es",
    path: "/es/influencer/list/all/",
    meta: indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___pt",
    path: "/pt/influencer/list/all/",
    meta: indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___it",
    path: "/it/influencer/list/all/",
    meta: indexVYRsRCxxAhIG1poSh7_45ys7K2P6x423lGSPXnn7DSMAsMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-fans___en",
    path: "/en/influencer/list/fans/",
    meta: fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___fr",
    path: "/fr/influencer/list/fans/",
    meta: fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___de",
    path: "/de/influencer/list/fans/",
    meta: fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___es",
    path: "/es/influencer/list/fans/",
    meta: fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___pt",
    path: "/pt/influencer/list/fans/",
    meta: fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___it",
    path: "/it/influencer/list/fans/",
    meta: fans8p_szY_45JCh2kdKK2iRUmUTTKJ_lHfdA8e9_450AjOVcsYMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-new-arrivals___en",
    path: "/en/influencer/list/new-arrivals/",
    meta: new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___fr",
    path: "/fr/influencer/list/new-arrivals/",
    meta: new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___de",
    path: "/de/influencer/list/new-arrivals/",
    meta: new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___es",
    path: "/es/influencer/list/new-arrivals/",
    meta: new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___pt",
    path: "/pt/influencer/list/new-arrivals/",
    meta: new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___it",
    path: "/it/influencer/list/new-arrivals/",
    meta: new_45arrivalsKtHiTGRMQCbqaQGgrJlJlYJdRWn_QkGInehaA__45zKakMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-popular___en",
    path: "/en/influencer/list/popular/",
    meta: popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___fr",
    path: "/fr/influencer/list/popular/",
    meta: popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___de",
    path: "/de/influencer/list/popular/",
    meta: popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___es",
    path: "/es/influencer/list/popular/",
    meta: popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___pt",
    path: "/pt/influencer/list/popular/",
    meta: popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___it",
    path: "/it/influencer/list/popular/",
    meta: popularV57ItXQgtWtO2xsx0F1MG25PAEj6POTbjCmFHYSUf60Meta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-tags-slug___en",
    path: "/en/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___fr",
    path: "/fr/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___de",
    path: "/de/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___es",
    path: "/es/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___pt",
    path: "/pt/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___it",
    path: "/it/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93AENucYHFV_45RKmSafUvey7l3soxv5I0WV6MrUrCFBWm8Meta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-parameter___en",
    path: "/en/influencer/parameter/",
    meta: parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___fr",
    path: "/fr/influencer/parameter/",
    meta: parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___de",
    path: "/de/influencer/parameter/",
    meta: parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___es",
    path: "/es/influencer/parameter/",
    meta: parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___pt",
    path: "/pt/influencer/parameter/",
    meta: parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___it",
    path: "/it/influencer/parameter/",
    meta: parameterhSff1mb9e6Sz7iPeOGXFFEpbnPAi9ES2aB8GuoNm8KIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-profile-slug-edit___en",
    path: "/en/influencer/profile/:slug()/edit/",
    meta: editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___fr",
    path: "/fr/influencer/profile/:slug()/edit/",
    meta: editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___de",
    path: "/de/influencer/profile/:slug()/edit/",
    meta: editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___es",
    path: "/es/influencer/profile/:slug()/edit/",
    meta: editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___pt",
    path: "/pt/influencer/profile/:slug()/edit/",
    meta: editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___it",
    path: "/it/influencer/profile/:slug()/edit/",
    meta: editHPQ9_4548LhhAATr37leIzW6rhe0_km_45toY8G_svpwB9kMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug___en",
    path: "/en/influencer/profile/:slug()/",
    meta: indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___fr",
    path: "/fr/influencer/profile/:slug()/",
    meta: indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___de",
    path: "/de/influencer/profile/:slug()/",
    meta: indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___es",
    path: "/es/influencer/profile/:slug()/",
    meta: indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___pt",
    path: "/pt/influencer/profile/:slug()/",
    meta: indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___it",
    path: "/it/influencer/profile/:slug()/",
    meta: indexfxWOgeWUeYbQdKsmn5LlGRBeehdb8CkEgSfY77P0le0Meta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile___en",
    path: "/en/influencer/profile/",
    meta: indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___fr",
    path: "/fr/influencer/profile/",
    meta: indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___de",
    path: "/de/influencer/profile/",
    meta: indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___es",
    path: "/es/influencer/profile/",
    meta: indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___pt",
    path: "/pt/influencer/profile/",
    meta: indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___it",
    path: "/it/influencer/profile/",
    meta: indexIfyFUQ6QnRdanS3iC05ZP_45DzjbqYa9mdFgYTsrJTtqAMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-signup-discovery___en",
    path: "/en/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___fr",
    path: "/fr/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___de",
    path: "/de/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___es",
    path: "/es/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___pt",
    path: "/pt/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___it",
    path: "/it/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup___en",
    path: "/en/influencer/signup/",
    meta: indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___fr",
    path: "/fr/influencer/signup/",
    meta: indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___de",
    path: "/de/influencer/signup/",
    meta: indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___es",
    path: "/es/influencer/signup/",
    meta: indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___pt",
    path: "/pt/influencer/signup/",
    meta: indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___it",
    path: "/it/influencer/signup/",
    meta: indexXCme1CNla5GB7GlisFpV917ernZ7Ejlr3t8KLYUJ0n8Meta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup-v2-bio___en",
    path: "/en/influencer/signup/v2/bio/",
    meta: biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___fr",
    path: "/fr/influencer/signup/v2/bio/",
    meta: biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___de",
    path: "/de/influencer/signup/v2/bio/",
    meta: biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___es",
    path: "/es/influencer/signup/v2/bio/",
    meta: biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___pt",
    path: "/pt/influencer/signup/v2/bio/",
    meta: biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___it",
    path: "/it/influencer/signup/v2/bio/",
    meta: biouwS0fM0AiZwahoaePdx1zS2cgQN3Y7KtC80FreTHY_oMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-genres___en",
    path: "/en/influencer/signup/v2/genres/",
    meta: genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___fr",
    path: "/fr/influencer/signup/v2/genres/",
    meta: genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___de",
    path: "/de/influencer/signup/v2/genres/",
    meta: genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___es",
    path: "/es/influencer/signup/v2/genres/",
    meta: genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___pt",
    path: "/pt/influencer/signup/v2/genres/",
    meta: genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___it",
    path: "/it/influencer/signup/v2/genres/",
    meta: genresWNHGq6mfqMW78BKERlycZAd8Qn6XyQlOXw7QX1_EDAMMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___en",
    path: "/en/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___fr",
    path: "/fr/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___de",
    path: "/de/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___es",
    path: "/es/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___pt",
    path: "/pt/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___it",
    path: "/it/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releaseNuydKT04sgHYjiNImd2QcNQF43h_rx3HTu5LOchstLIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-name-country___en",
    path: "/en/influencer/signup/v2/name-country/",
    meta: name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___fr",
    path: "/fr/influencer/signup/v2/name-country/",
    meta: name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___de",
    path: "/de/influencer/signup/v2/name-country/",
    meta: name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___es",
    path: "/es/influencer/signup/v2/name-country/",
    meta: name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___pt",
    path: "/pt/influencer/signup/v2/name-country/",
    meta: name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___it",
    path: "/it/influencer/signup/v2/name-country/",
    meta: name_45countrySF5KTKGX0VFjJGyMDMEOjSqJdikaZ5mWRzuKgPSepTUMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-opportunities___en",
    path: "/en/influencer/signup/v2/opportunities/",
    meta: opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/opportunities.vue")
  },
  {
    name: "influencer-signup-v2-opportunities___fr",
    path: "/fr/influencer/signup/v2/opportunities/",
    meta: opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/opportunities.vue")
  },
  {
    name: "influencer-signup-v2-opportunities___de",
    path: "/de/influencer/signup/v2/opportunities/",
    meta: opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/opportunities.vue")
  },
  {
    name: "influencer-signup-v2-opportunities___es",
    path: "/es/influencer/signup/v2/opportunities/",
    meta: opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/opportunities.vue")
  },
  {
    name: "influencer-signup-v2-opportunities___pt",
    path: "/pt/influencer/signup/v2/opportunities/",
    meta: opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/opportunities.vue")
  },
  {
    name: "influencer-signup-v2-opportunities___it",
    path: "/it/influencer/signup/v2/opportunities/",
    meta: opportunitiesdyw_45vzVqWh11mQv5vzpxk_45BI_45STBLoubemaWPFje_45_UMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/opportunities.vue")
  },
  {
    name: "influencer-signup-v2-picture___en",
    path: "/en/influencer/signup/v2/picture/",
    meta: picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___fr",
    path: "/fr/influencer/signup/v2/picture/",
    meta: picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___de",
    path: "/de/influencer/signup/v2/picture/",
    meta: picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___es",
    path: "/es/influencer/signup/v2/picture/",
    meta: picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___pt",
    path: "/pt/influencer/signup/v2/picture/",
    meta: picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___it",
    path: "/it/influencer/signup/v2/picture/",
    meta: picture_9bzP6wm1_rW7pASmUauGFH1APudV_45H4_OhCq8RXmTIMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___en",
    path: "/en/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___fr",
    path: "/fr/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___de",
    path: "/de/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___es",
    path: "/es/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___pt",
    path: "/pt/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___it",
    path: "/it/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsqR_t_9DCzxzp_45jSd0rIz553gYnNVgxMLA8sH3H5FQwYMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-type___en",
    path: "/en/influencer/signup/v2/type/",
    meta: typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___fr",
    path: "/fr/influencer/signup/v2/type/",
    meta: typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___de",
    path: "/de/influencer/signup/v2/type/",
    meta: typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___es",
    path: "/es/influencer/signup/v2/type/",
    meta: typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___pt",
    path: "/pt/influencer/signup/v2/type/",
    meta: typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___it",
    path: "/it/influencer/signup/v2/type/",
    meta: typeoRzMLqWJNq74CWoXmbPiffMs449LiUECUGznGcLJQpEMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-website-links___en",
    path: "/en/influencer/signup/v2/website-links/",
    meta: website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___fr",
    path: "/fr/influencer/signup/v2/website-links/",
    meta: website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___de",
    path: "/de/influencer/signup/v2/website-links/",
    meta: website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___es",
    path: "/es/influencer/signup/v2/website-links/",
    meta: website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___pt",
    path: "/pt/influencer/signup/v2/website-links/",
    meta: website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___it",
    path: "/it/influencer/signup/v2/website-links/",
    meta: website_45linksfyflV4Ddsr98eLATlArEHQsfc98H94EIb7ioi09iKO0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta?.name,
    path: "/en/influencer/wallet/",
    meta: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___en",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___en",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___en",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta?.name,
    path: "/fr/influencer/wallet/",
    meta: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___fr",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___fr",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___fr",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta?.name,
    path: "/de/influencer/wallet/",
    meta: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___de",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___de",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___de",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta?.name,
    path: "/es/influencer/wallet/",
    meta: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___es",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___es",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___es",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta?.name,
    path: "/pt/influencer/wallet/",
    meta: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___pt",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___pt",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___pt",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta?.name,
    path: "/it/influencer/wallet/",
    meta: walletH_phox46rRnwPQQoRqhR1vFrHT5mWSqmprwHosjqLm0Meta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___it",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___it",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___it",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: "influencer-widget-slug___en",
    path: "/en/influencer/widget/:slug()/",
    meta: _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___fr",
    path: "/fr/influencer/widget/:slug()/",
    meta: _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___de",
    path: "/de/influencer/widget/:slug()/",
    meta: _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___es",
    path: "/es/influencer/widget/:slug()/",
    meta: _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___pt",
    path: "/pt/influencer/widget/:slug()/",
    meta: _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___it",
    path: "/it/influencer/widget/:slug()/",
    meta: _91slug_93LAIDXWCQvC1Ra_45E7r6AdSYDWc09xvoRGhhCTVwmUnSYMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget___en",
    path: "/en/influencer/widget/",
    meta: indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___fr",
    path: "/fr/influencer/widget/",
    meta: indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___de",
    path: "/de/influencer/widget/",
    meta: indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___es",
    path: "/es/influencer/widget/",
    meta: indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___pt",
    path: "/pt/influencer/widget/",
    meta: indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___it",
    path: "/it/influencer/widget/",
    meta: indexiBNbE_45sas289AH5RA1ChmXDyLeZSFwdRdYnXyc3_cNoMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget-redirect-slug___en",
    path: "/en/influencer/widget/redirect/:slug()/",
    meta: _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___fr",
    path: "/fr/influencer/widget/redirect/:slug()/",
    meta: _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___de",
    path: "/de/influencer/widget/redirect/:slug()/",
    meta: _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___es",
    path: "/es/influencer/widget/redirect/:slug()/",
    meta: _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___pt",
    path: "/pt/influencer/widget/redirect/:slug()/",
    meta: _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___it",
    path: "/it/influencer/widget/redirect/:slug()/",
    meta: _91slug_9359bNDVCxM0Iwm_S9wwVR8miZqsYBut92fy2Atse7TecMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "lp-slug___en",
    path: "/en/lp/:slug()/",
    meta: _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___fr",
    path: "/fr/lp/:slug()/",
    meta: _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___de",
    path: "/de/lp/:slug()/",
    meta: _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___es",
    path: "/es/lp/:slug()/",
    meta: _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___pt",
    path: "/pt/lp/:slug()/",
    meta: _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___it",
    path: "/it/lp/:slug()/",
    meta: _91slug_93iCwDsJgM28iEo3ujjpkWCUB1XyTidWPn5V7iq1GdVpQMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "partners___en",
    path: "/en/partners/",
    meta: partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta || {},
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___fr",
    path: "/fr/partners/",
    meta: partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta || {},
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___de",
    path: "/de/partners/",
    meta: partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta || {},
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___es",
    path: "/es/partners/",
    meta: partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta || {},
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___pt",
    path: "/pt/partners/",
    meta: partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta || {},
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___it",
    path: "/it/partners/",
    meta: partnersqtKH854UTam1IRaqaLSo5HdQS2hNK1LsJyX4_gFjwHYMeta || {},
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "payin-failure___en",
    path: "/en/payin/failure/",
    meta: failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___fr",
    path: "/fr/payin/failure/",
    meta: failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___de",
    path: "/de/payin/failure/",
    meta: failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___es",
    path: "/es/payin/failure/",
    meta: failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___pt",
    path: "/pt/payin/failure/",
    meta: failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___it",
    path: "/it/payin/failure/",
    meta: failureMouUJkyfXDM64A7Ven8CJzDwIy3Z6hhvBopfKKWBkz4Meta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-paypal-retry___en",
    path: "/en/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___fr",
    path: "/fr/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___de",
    path: "/de/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___es",
    path: "/es/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___pt",
    path: "/pt/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___it",
    path: "/it/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "purge___en",
    path: "/en/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___fr",
    path: "/fr/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___de",
    path: "/de/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___es",
    path: "/es/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___pt",
    path: "/pt/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___it",
    path: "/it/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password/",
    meta: reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password/",
    meta: reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___de",
    path: "/de/reset-password/",
    meta: reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___es",
    path: "/es/reset-password/",
    meta: reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___pt",
    path: "/pt/reset-password/",
    meta: reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___it",
    path: "/it/reset-password/",
    meta: reset_45passwordJeZu6zMkSnXT_45y9_459VGrtbQRGluv8L_2vwX8pBjjGHMMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "signup___en",
    path: "/en/signup/",
    meta: signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___fr",
    path: "/fr/signup/",
    meta: signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___de",
    path: "/de/signup/",
    meta: signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___es",
    path: "/es/signup/",
    meta: signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___pt",
    path: "/pt/signup/",
    meta: signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___it",
    path: "/it/signup/",
    meta: signup0UOiCnNs3z15dPTXIS70WWv0vfSe904b4p1lWElqHukMeta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "spotify-playlist-id___en",
    path: "/en/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___fr",
    path: "/fr/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___de",
    path: "/de/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___es",
    path: "/es/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___pt",
    path: "/pt/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___it",
    path: "/it/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id-loading___en",
    path: "/en/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___fr",
    path: "/fr/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___de",
    path: "/de/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___es",
    path: "/es/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___pt",
    path: "/pt/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___it",
    path: "/it/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-share-track___en",
    path: "/en/spotify/share/track/",
    meta: trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___fr",
    path: "/fr/spotify/share/track/",
    meta: trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___de",
    path: "/de/spotify/share/track/",
    meta: trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___es",
    path: "/es/spotify/share/track/",
    meta: trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___pt",
    path: "/pt/spotify/share/track/",
    meta: trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___it",
    path: "/it/spotify/share/track/",
    meta: trackz8VUDGJjFgu7HsIfzr6LUQZvguJSfQp8xCx_45yvd0HgUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "test___en",
    path: "/en/test/",
    meta: testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___fr",
    path: "/fr/test/",
    meta: testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___de",
    path: "/de/test/",
    meta: testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___es",
    path: "/es/test/",
    meta: testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___pt",
    path: "/pt/test/",
    meta: testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___it",
    path: "/it/test/",
    meta: testd2d0LMprHhm25dLKRCuQeAAEzXy5CkRBOY7zJpIGzVUMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "tools-slug___en",
    path: "/en/tools/:slug(.*)*/",
    meta: _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta || {},
    component: () => import("/root/front/pages/tools/[...slug].vue")
  },
  {
    name: "tools-slug___fr",
    path: "/fr/tools/:slug(.*)*/",
    meta: _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta || {},
    component: () => import("/root/front/pages/tools/[...slug].vue")
  },
  {
    name: "tools-slug___de",
    path: "/de/tools/:slug(.*)*/",
    meta: _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta || {},
    component: () => import("/root/front/pages/tools/[...slug].vue")
  },
  {
    name: "tools-slug___es",
    path: "/es/tools/:slug(.*)*/",
    meta: _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta || {},
    component: () => import("/root/front/pages/tools/[...slug].vue")
  },
  {
    name: "tools-slug___pt",
    path: "/pt/tools/:slug(.*)*/",
    meta: _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta || {},
    component: () => import("/root/front/pages/tools/[...slug].vue")
  },
  {
    name: "tools-slug___it",
    path: "/it/tools/:slug(.*)*/",
    meta: _91_46_46_46slug_93bxKpeHatSJf8qCBeMNP1jPPtiaaPqDHQmbpfYlhcjOUMeta || {},
    component: () => import("/root/front/pages/tools/[...slug].vue")
  }
]