import { acceptHMRUpdate, defineStore } from 'pinia'

import {
  provideGetTrackTags,
  providePatchTrackTags,
} from '~/api-core/Draft/DraftTrack'

import type { TagTarget } from '~/types/tagTarget'

export const draftTrackTagsInitialState = () => ({
  liked: {},
  hated: {},
  neutral: {},
  exclusivity: {},
  identity: {
    lyrics_lang: [] as number[],
    // TODO: test this - these didn't exist before but maybe should
    format: [] as number[],
    mood: [] as number[],
    subgenre: [] as number[],
    track_age: [] as number[],
    // end TODO
  },
})

const state = draftTrackTagsInitialState()
export type TrackTagsState = ReturnType<typeof draftTrackTagsInitialState>

export const useDraftTrackTagsStore = defineStore('draftTrackTags', () => {
  const { coreFetch } = useProvideCoreFetch()

  const liked = ref(state.liked)
  const hated = ref(state.hated)
  const neutral = ref(state.neutral)
  const exclusivity = ref(state.exclusivity)
  const identity = ref(state.identity)

  function getStateRef(key: keyof TrackTagsState) {
    switch (key) {
      case 'liked':
        return liked
      case 'hated':
        return hated
      case 'neutral':
        return neutral
      case 'exclusivity':
        return exclusivity
      case 'identity':
        return identity
    }
  }
  function SET(tags: Partial<TrackTagsState>) {
    Object.keys(tags).forEach((key) => {
      const keyName = key as keyof TrackTagsState
      const keyVal = tags[keyName] as TrackTagsState[keyof TrackTagsState]

      const stateRef = getStateRef(keyName)
      if (stateRef === undefined) return

      stateRef.value = keyVal
    })
  }

  function RESET() {
    SET({ ...state })
  }

  function SET_TRACK_IDENTITY_TAGS(patch: TrackTagsState['identity']) {
    identity.value = patch
  }
  async function FETCH(
    trackId: number,
  ): Promise<{ values: { identity: TrackTagsState['identity'] } } | unknown> {
    const getTrackTags = provideGetTrackTags(coreFetch)

    const values: {
      identity: TrackTagsState['identity']
    } = await getTrackTags(trackId).catch(() => ({
      identity: {
        ...draftTrackTagsInitialState().identity,
      },
    }))

    SET_TRACK_IDENTITY_TAGS({ ...values.identity })
    return { values }
  }

  function UPDATE_FROM_PATCH({
    patch,
    track_id: trackId,
  }: {
    patch: Partial<TrackTagsState>
    track_id: number
  }): Promise<TrackTagsState> {
    const patchTrackTags = providePatchTrackTags(coreFetch)
    return patchTrackTags(trackId, patch)
  }

  // TODO recheck but seems like this is not used
  // const GET_DISALLOWED = computed(() => {
  //   return function (category: keyof TrackTagsState, target: TagTarget) {
  //     category = category.toLowerCase() as keyof TrackTagsState
  //     const allowedKeys = Object.keys(state) as TagTarget[]
  //     const index = allowedKeys.findIndex(
  //       (key) => (key as string) === (category as string),
  //     )

  //     if ((category as string) === 'exclusivity') {
  //       return [] as number[]
  //     } else if (index !== -1) {
  //       allowedKeys.splice(index, 1)
  //       return [
  //         ...allowedKeys.reduce((accumulator, categoryId) => {
  //           const subCat = getStateRef(categoryId).value
  //           if (target in subCat)
  //             subCat[target].forEach((tagId: number) => accumulator.add(tagId))

  //           return accumulator
  //         }, new Set()),
  //       ] as number[]
  //     }
  //     console.warn(
  //       `[TAGS]: ${category} is not a valid parameter in GET_DISALLOWED`,
  //     )
  //     return [] as number[]
  //   }
  // })
  // const GET_TAGS = computed(() => {
  //   return function (category: keyof TrackTagsState, target: TagTarget) {
  //     category = category.toLowerCase() as keyof TrackTagsState
  //     if (Object.keys(state).includes(category)) {
  //       // @ts-expect-error Can't make it expect-error
  //       const out: number[] | undefined = state?.[category]?.[target]

  //       if (typeof out !== 'undefined') {
  //         return out
  //       } else {
  //         console.warn(
  //           `[TAGS]: ${target} is not a valid parameter in GET_CATEGORY`,
  //         )
  //       }
  //     } else {
  //       console.warn(
  //         `[TAGS]: ${category} is not a valid parameter in GET_CATEGORY`,
  //       )
  //     }

  //     return []
  //   }
  // })

  return {
    // state
    liked,
    hated,
    neutral,
    exclusivity,
    identity,

    // actions
    SET,
    RESET,
    SET_TRACK_IDENTITY_TAGS,
    FETCH,
    UPDATE_FROM_PATCH,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDraftTrackTagsStore, import.meta.hot),
  )
}
