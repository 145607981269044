import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payloadToJson_42ACzfsCYH_kmFrUmfSnKjQiFWGYTzOky2yQHn7WluU from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_et4ODBTLXXPhdNQELhhAnGGqjAhD1sIEfdX8qNNcUcA from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_06kpebQlP65FYg4eNPh_YophVNx_CzuETixaHa5cbF8 from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fV5mxYOTfp1MBra45dnTTambR8EvBb9VNG2uZaZRuZQ from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig__QlIgs4p1UgoLAb3AxeMp0K0wLSLcg9rKpHg7JBtqfw from "/root/front/node_modules/.pnpm/nuxt-site-config@3.1.5_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_UUM58rNUtZgiWPLuu6iI2gWrY915vUmqtZKgiR9V6OM from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_d5AwTplhgMREJvgip4TO8lBHqRpijszmPGhKZAEziZo from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_9QU62Bx2uu1kiUzmjvQrKEOUV9yBYVq57JzxAPLtFQc from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_JNp8h4Ekfd3gxeeOIHlg0FfdkvCo3B9oIAMbpBd3FAc from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_iP_UkREGOKpvx5qVq5V7B3HxcWfBSKhvvWOaF_3hzJc from "/root/front/node_modules/.pnpm/@sentry+nuxt@9.5.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@_8e1b849b20bab7f1c42fb83c032e9e6f/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/root/front/.nuxt/sentry-client-config.mjs";
import i18n_FRL_oFmpbOdj5tSx57yBvWgv4UPZfXaIFJSgtaeWCpw from "/root/front/node_modules/.pnpm/nuxt-site-config@3.1.5_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_9S7dfRTouVKlz0d3os_GWHD0D6Jcqvsejtpm6udlfXc from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_ZLylsD3GeOw6FEw9RjZNOGRWhOQhJitHZEg1TqX1kbU from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_jGivfSwNLf4wM1F1PenDrV_dktr72SDsmBwqkRF91Z4 from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import polyfills_client_n4kVvEA2TSp_CCJ_NkYGcHWGqzj_6COcWPX1nfgUO6g from "/root/front/plugins/polyfills.client.ts";
import globalNuxtLink_eb2Mu_YNqiRwIuoCnyEQ5Zi60_SgA6IaWhZkonDKveM from "/root/front/plugins/globalNuxtLink.ts";
import pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y from "/root/front/plugins/pinia.ts";
import globalMixin_RUmjfCuhPru9Xncu_rWiFp2UqxznEMvuBwd940iPvLg from "/root/front/plugins/globalMixin.ts";
import initCsrf_U_nuV42NcGnhT40q_HjyDLknWI6_sW1EOWL_Z5fH99w from "/root/front/plugins/initCsrf.ts";
import segment_6bFmHMXIsz7hReU_4cBKNs7sL8o4VaO_VZ807nb3vvs from "/root/front/plugins/segment.ts";
import segmentPageTracking_ERl_kAqZzra0O_2Zmc07VNOY0tC1TiluGtIckO9fIGk from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_eTsiF8x5dNTME_B5dZ_7hoUwFAqaCUkgeRERkCUcxBQ from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_lKbBtiiX6ssGAYITsKjFPKC1eB8HBm2hCUsj3vZAcnM from "/root/front/plugins/growthBook.ts";
import stripe_OFqvNrtTqhOp68PPSu3qxkufPyrJwEgwkh5cpNgpaZs from "/root/front/plugins/stripe.ts";
import vueGtag_6K97P6FUJUwyL8ppRmKdMaZYRYdcMxeb7nbG_vCakjo from "/root/front/plugins/vueGtag.ts";
import preview_STvDyCcadLGlTPYb2fORS4APpgQL80601RmQlXD0LPI from "/root/front/plugins/preview.ts";
import customerIO_NI58QCpDLrTzFcMjE_GnXz7LxJurGjjhWpOIdfQTDkY from "/root/front/plugins/customerIO.ts";
import ssg_detect_e2iIdw4Yiz_Ejyw46xAdZlX86afzK5shtUtXxQQD2zI from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  payloadToJson_42ACzfsCYH_kmFrUmfSnKjQiFWGYTzOky2yQHn7WluU,
  revive_payload_client_et4ODBTLXXPhdNQELhhAnGGqjAhD1sIEfdX8qNNcUcA,
  unhead_06kpebQlP65FYg4eNPh_YophVNx_CzuETixaHa5cbF8,
  router_fV5mxYOTfp1MBra45dnTTambR8EvBb9VNG2uZaZRuZQ,
  _0_siteConfig__QlIgs4p1UgoLAb3AxeMp0K0wLSLcg9rKpHg7JBtqfw,
  payload_client_UUM58rNUtZgiWPLuu6iI2gWrY915vUmqtZKgiR9V6OM,
  navigation_repaint_client_d5AwTplhgMREJvgip4TO8lBHqRpijszmPGhKZAEziZo,
  chunk_reload_client_9QU62Bx2uu1kiUzmjvQrKEOUV9yBYVq57JzxAPLtFQc,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_JNp8h4Ekfd3gxeeOIHlg0FfdkvCo3B9oIAMbpBd3FAc,
  sentry_client_iP_UkREGOKpvx5qVq5V7B3HxcWfBSKhvvWOaF_3hzJc,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  i18n_FRL_oFmpbOdj5tSx57yBvWgv4UPZfXaIFJSgtaeWCpw,
  switch_locale_path_ssr_9S7dfRTouVKlz0d3os_GWHD0D6Jcqvsejtpm6udlfXc,
  route_locale_detect_ZLylsD3GeOw6FEw9RjZNOGRWhOQhJitHZEg1TqX1kbU,
  i18n_jGivfSwNLf4wM1F1PenDrV_dktr72SDsmBwqkRF91Z4,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  polyfills_client_n4kVvEA2TSp_CCJ_NkYGcHWGqzj_6COcWPX1nfgUO6g,
  globalNuxtLink_eb2Mu_YNqiRwIuoCnyEQ5Zi60_SgA6IaWhZkonDKveM,
  pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y,
  globalMixin_RUmjfCuhPru9Xncu_rWiFp2UqxznEMvuBwd940iPvLg,
  initCsrf_U_nuV42NcGnhT40q_HjyDLknWI6_sW1EOWL_Z5fH99w,
  segment_6bFmHMXIsz7hReU_4cBKNs7sL8o4VaO_VZ807nb3vvs,
  segmentPageTracking_ERl_kAqZzra0O_2Zmc07VNOY0tC1TiluGtIckO9fIGk,
  closeCookiebotDialog_eTsiF8x5dNTME_B5dZ_7hoUwFAqaCUkgeRERkCUcxBQ,
  growthBook_lKbBtiiX6ssGAYITsKjFPKC1eB8HBm2hCUsj3vZAcnM,
  stripe_OFqvNrtTqhOp68PPSu3qxkufPyrJwEgwkh5cpNgpaZs,
  vueGtag_6K97P6FUJUwyL8ppRmKdMaZYRYdcMxeb7nbG_vCakjo,
  preview_STvDyCcadLGlTPYb2fORS4APpgQL80601RmQlXD0LPI,
  customerIO_NI58QCpDLrTzFcMjE_GnXz7LxJurGjjhWpOIdfQTDkY,
  ssg_detect_e2iIdw4Yiz_Ejyw46xAdZlX86afzK5shtUtXxQQD2zI
]