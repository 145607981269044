import { defineStore } from 'pinia'

import { useCartPromoStore } from './cartPromo'
import { useMiscDraftStore } from './miscDraft'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import {
  provideGetDraftCart,
  provideGetPackCart,
} from '~/api-core/Cart/FetchCart'
import {
  provideAutoApplyBestPromo,
  provideCheckPromoCode,
} from '~/api-core/Cart/Promos'
import {
  provideAddItemToCart,
  provideRemoveItemFromCart,
} from '~/api-core/Cart/UpdateCart'

import type {
  APICart,
  CampaignCartItem,
  Cart,
  CartItem,
  CartItemIdentifier,
  CartStatus,
  PackCartItem,
  PromoCartItem,
  UpsellCartItem,
} from '~/types/cart'

export const useCartStore = defineStore('cart', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const cartPromoStore = useCartPromoStore($pinia)
  const miscDraftStore = useMiscDraftStore($pinia)
  const bandStore = useUserBandStore($pinia)

  const id = ref(0)
  const bandId = ref(0)
  const price = ref(0)
  const status = ref('' as CartStatus)
  const items = ref([] as CartItem[])

  const CAMPAIGN_ITEM = computed(() => {
    return (
      (items.value.filter(
        (item) => item.type === 'campaigncartitem',
      )[0] as CampaignCartItem) || null
    )
  })

  const PACK_ITEM = computed(() => {
    return (
      (items.value.filter(
        (item) => item.type === 'packcartitem',
      )[0] as PackCartItem) || null
    )
  })

  const PROMO_ITEM = computed(() => {
    return (
      (items.value.filter(
        (item) => item.type === 'promocartitem',
      )[0] as PromoCartItem) || null
    )
  })

  const UPSELL_ITEMS = computed<UpsellCartItem[]>(() =>
    items.value.filter((item) => item.type === 'upsellcartitem'),
  )

  const CAMPAIGN_COST_AFTER_PROMO = computed(() => {
    if (!CAMPAIGN_ITEM.value) return 0

    // promo price is the amount of grooviz off the campaign, it is a negative number
    return UPSELL_ITEMS.value
      ? CAMPAIGN_ITEM.value.price_after_promo +
          UPSELL_ITEMS.value.reduce(
            (acc, { price_after_promo: priceAfterPromo }) =>
              acc + priceAfterPromo,
            0,
          )
      : CAMPAIGN_ITEM.value.price_after_promo
  })

  function SET_CART(cart: Cart | APICart): void {
    id.value = cart.id
    bandId.value = cart.bandId || (cart as APICart).band_id
    price.value = cart.price
    status.value = cart.status
    items.value = cart.items

    if (PROMO_ITEM.value?.id) FETCH_CART_PROMO(PROMO_ITEM.value.promo)
  }

  async function FETCH_DRAFT_CART(draftId: number): Promise<void> {
    try {
      const getDraftCart = provideGetDraftCart(coreFetch)
      const cart = await getDraftCart(draftId)
      SET_CART(cart)
    } catch (_) {}
  }

  async function FETCH_PACK_CART(packId: number): Promise<void> {
    const getPackCart = provideGetPackCart(coreFetch)
    const cart = await getPackCart(packId, bandStore.id)
    SET_CART(cart)
  }

  async function UPDATE_PACK(packId: number): Promise<void> {
    if (id.value === 0) return

    if (PACK_ITEM.value?.pack === packId) return
    if (PACK_ITEM.value?.id) await DELETE_CART_ITEM(PACK_ITEM.value.id)
    if (packId)
      await ADD_ITEM_TO_CART({ type: 'packcartitem', item: { pack: packId } })
  }

  async function ADD_ITEM_TO_CART(item: CartItemIdentifier): Promise<void> {
    const addItemToCart = provideAddItemToCart(coreFetch)

    const updatedCart = await addItemToCart(id.value, item)
    if (updatedCart) SET_CART(updatedCart)
  }

  async function DELETE_CART_ITEM(itemId: number): Promise<void> {
    const removeItemFromCart = provideRemoveItemFromCart(coreFetch)
    const updatedCart = await removeItemFromCart(itemId)

    if (updatedCart.id === id.value) SET_CART(updatedCart)
  }

  async function FETCH_CART_PROMO(promoCode: string) {
    const checkPromoCode = provideCheckPromoCode(coreFetch)
    const promo = await checkPromoCode(promoCode)
    cartPromoStore.SET_CART_PROMO(promo)
  }

  async function FETCH_CART_AUTO_APPLY_PROMO() {
    if (id.value === 0) return null

    const autoApplyBestPromo = provideAutoApplyBestPromo(coreFetch)
    const updatedCart = await autoApplyBestPromo(id.value)
    SET_CART(updatedCart)

    // This is only used to determine if user is SC Next Pro and display the SC promo banner in recap
    // Remove this logic as soon as SoundCloud Next Pro promo is removed
    PROMO_ITEM.value?.promo === 'VIPSOUNDCLOUDNEXTPRO'
      ? miscDraftStore.SET_USES_SOUND_CLOUD_NEXT_PROMO_CODE(true)
      : miscDraftStore.SET_USES_SOUND_CLOUD_NEXT_PROMO_CODE(false)
  }

  function REMOVE_PROMO_FROM_CART() {
    if (!PROMO_ITEM.value) return
    DELETE_CART_ITEM(PROMO_ITEM.value.id)
    cartPromoStore.RESET()
  }

  function RESET() {
    id.value = 0
    bandId.value = 0
    price.value = 0
    status.value = '' as CartStatus
    items.value = []
    cartPromoStore.RESET()
  }

  return {
    id,
    bandId,
    items,
    price,
    status,

    CAMPAIGN_COST_AFTER_PROMO,
    CAMPAIGN_ITEM,
    PACK_ITEM,
    PROMO_ITEM,
    UPSELL_ITEMS,

    ADD_ITEM_TO_CART,
    DELETE_CART_ITEM,
    FETCH_CART_AUTO_APPLY_PROMO,
    FETCH_CART_PROMO,
    FETCH_DRAFT_CART,
    FETCH_PACK_CART,
    REMOVE_PROMO_FROM_CART,
    RESET,
    SET_CART,
    UPDATE_PACK,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
