import { acceptHMRUpdate, defineStore } from 'pinia'

import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandSetStore } from './userBandSet'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import type { Band } from '~/types/band'

export type UserBandState = Pick<ReturnType<typeof useUserBandStore>, 'id'>

export const useUserBandStore = defineStore('userBand', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const userBandSetStore = useUserBandSetStore($pinia)
  const tagStore = useTagStore($pinia)

  const id = ref(0)

  const BAND_DATA = computed<Band | undefined>(() => {
    if (!id.value) return

    const bandSet = userBandSetStore.LIVE_BANDS
    return bandSet.find((band) => band.id === id.value)
  })

  const IS_BAND_INCOMPLETE = computed(() => {
    // check that at least one band has info (otherwise users with multiple bands will always see the incomplete band message)
    const FIRST_BAND = userBandSetStore.LIVE_BANDS[0]

    if (!FIRST_BAND) return true

    // FIXME: The only field that might reveal if a user has completed sign up is the target_release field
    // otherwise there's no need to have tracks or media links to have a complete band on Groover
    return !(
      FIRST_BAND.target_release ||
      FIRST_BAND.track_set?.length ||
      FIRST_BAND.media_links?.spotify
    )
  })

  const BAND_TRACKING_INFO = computed(() => {
    return {
      id_band: BAND_DATA.value?.id || 0,
      band_name: BAND_DATA.value?.name || '',
    }
  })

  const HAS_BAND_SELECTED = computed<boolean>(() => {
    return id.value > 0
  })

  const US_IS_ONLY_COUNTRY_FOR_BAND = computed<boolean>(() => {
    const countries = BAND_DATA.value?.tags?.identity?.country || []

    if (countries.length && countries.length === 1) {
      const tagName = tagStore.GET_TAG_FROM_ID(countries[0])?.name
      return Boolean(tagName && tagName === 'united-states')
    }

    return false
  })

  function SET(newId: number) {
    id.value = newId
  }

  async function FETCH() {
    if (!userStore.IS_INF && !id.value) {
      try {
        const ret = (await coreFetch.$get<Band>('/band/band/select/')) ?? {}

        SET(ret?.id ?? 0)
        return ret
      } catch (err) {
        SET(0)
      }
    }
  }

  function PATCH(bandId: number) {
    if (!userStore.IS_BAND) return Promise.resolve()

    return coreFetch
      .$patch('/band/band/select/', { band_id: bandId })
      .then(() => {
        SET(bandId)
        userStore.GET_USER_SUB_COUNT()
      })
      .catch(/** mute error */)
  }

  async function SELECT_FIRST() {
    if (userBandSetStore.LIVE_BANDS.length)
      await PATCH(userBandSetStore.LIVE_BANDS[0].id)
  }

  function RESET() {
    SET(0)
  }

  return {
    // state
    id,

    // getters
    BAND_DATA,
    BAND_TRACKING_INFO,
    HAS_BAND_SELECTED,
    US_IS_ONLY_COUNTRY_FOR_BAND,
    IS_BAND_INCOMPLETE,

    // actions
    SET,
    FETCH,
    PATCH,
    SELECT_FIRST,
    RESET,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserBandStore, import.meta.hot))
