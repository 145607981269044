import * as Sentry from '@sentry/nuxt'

import { useDraftStore } from '~/stores/draft'
import { useUserStore } from '~/stores/user'

const dontResetDraftOnRoutes = [
  '/band/payin',
  '/band/sent',
  '/draft/add/link',
  '/draft/influencers',
  '/influencer/profile',
  '/payin/failure',
]

export default defineNuxtRouteMiddleware(async (to) => {
  const { $sentry, $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const draftStore = useDraftStore($pinia)

  if (
    !userStore.IS_BAND ||
    dontResetDraftOnRoutes.some((r) => to.path.includes(r)) ||
    draftStore.HAS_PRESELECTED_INFLUENCERS ||
    to.path.includes(`/draft/${to.params.id}`) ||
    to.path.includes(`/draft/${draftStore.id}`) ||
    to.fullPath.match(/draftId=\d+/g)
  )
    return

  try {
    await draftStore.RESET_CURRENT()
  } catch (err) {
    Sentry.captureException(err)
  }
})
