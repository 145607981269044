import type { Cart } from '~/types/cart'
import type { CoreFetch } from '~/types/CoreFetch'

export function provideCheckPromoCode(coreFetch: CoreFetch) {
  return async (promoCode: string) =>
    await coreFetch.$post<Pick<CartPromoState, 'id' | 'code' | 'actions'>>(
      `/wallet/promo`,
      { code: promoCode },
    )
}

export function provideAutoApplyBestPromo(coreFetch: CoreFetch) {
  return async (cartId: number) =>
    await coreFetch.$post<Cart>(`/cart/cart/${cartId}/auto_apply_best_promo/`)
}
