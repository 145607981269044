import validate from "/root/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_f3f969b8d2a7aa608fefdd06bf6de2c4/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45band_45select_45global from "/root/front/middleware/01.bandSelect.global.ts";
import _02_45reset_45draft_45global from "/root/front/middleware/02.resetDraft.global.ts";
import _03_45route_45trailing_45slash_45global from "/root/front/middleware/03.routeTrailingSlash.global.ts";
import _04_45redirect_45routes_45with_45locale_45prefix_45global from "/root/front/middleware/04.redirectRoutesWithLocalePrefix.global.ts";
import _05_45utm_45global from "/root/front/middleware/05.utm.global.ts";
export const globalMiddleware = [
  validate,
  _01_45band_45select_45global,
  _02_45reset_45draft_45global,
  _03_45route_45trailing_45slash_45global,
  _04_45redirect_45routes_45with_45locale_45prefix_45global,
  _05_45utm_45global
]
export const namedMiddleware = {
  "draft-id": () => import("/root/front/middleware/draftId.ts"),
  "draft-influencers": () => import("/root/front/middleware/draftInfluencers.ts"),
  "draft-shared-music": () => import("/root/front/middleware/draftSharedMusic.ts"),
  "draft-step": () => import("/root/front/middleware/draftStep.ts"),
  "get-cart-and-favorites": () => import("/root/front/middleware/getCartAndFavorites.ts"),
  "index-redirection": () => import("/root/front/middleware/indexRedirection.ts"),
  "influencer-dashboard": () => import("/root/front/middleware/influencerDashboard.ts"),
  "is-agency": () => import("/root/front/middleware/isAgency.ts"),
  "is-band": () => import("/root/front/middleware/isBand.ts"),
  "is-influencer": () => import("/root/front/middleware/isInfluencer.ts"),
  "is-logged-in": () => import("/root/front/middleware/isLoggedIn.ts"),
  "onboarding-flow": () => import("/root/front/middleware/onboardingFlow.ts"),
  "progressive-promos-check": () => import("/root/front/middleware/progressivePromosCheck.ts"),
  "redirect-missing-id-param": () => import("/root/front/middleware/redirectMissingIdParam.ts"),
  "tag-fetch": () => import("/root/front/middleware/tagFetch.ts")
}