/**
 * Use this middleware to redirect routes along with their locale prefixes.
 * This isn't possible using the built-in `routeRules` config in Nuxt.
 */
export default defineNuxtRouteMiddleware((to) => {
  const routesToRediect = [
    { from: '/cgs/', to: '/lp/cgs/', statusCode: 301 },
    { from: '/about/', to: '/lp/about/', statusCode: 301 },
  ]
  const foundRouteToRedirect = routesToRediect.find(
    (redirectRoute) =>
      to.path.endsWith(redirectRoute.from) &&
      // avoid endless redirection loops by making sure the redirect to path isn't the same as the current path
      // like in the case of /cgs/ -> /lp/cgs/
      !to.path.endsWith(redirectRoute.to),
  )

  if (!foundRouteToRedirect) return

  const { $localePath } = useNuxtApp()

  return navigateTo(
    {
      path: $localePath(foundRouteToRedirect.to),
      query: to.query,
      hash: to.hash,
    },
    { redirectCode: foundRouteToRedirect.statusCode },
  )
})
