<template>
  <div class="influencerActivateWrapper outerAdapt">
    <form class="innerAdapt" @submit.prevent="finalize">
      <div class="widthLimit">
        <div class="title">
          {{ $t('influencer.activate00') }}
        </div>
        <VInputEmail
          v-model="email"
          :refine-function="validateEmail"
          :refine-function-message="emailRefineMessage"
        />
        <VInputPassword v-model="password" show-confirmation-input />

        <div class="cbWrap">
          <VInputCheckboxRequired v-model="cgs" field-name="cgs">
            <template #label>
              <div class="checkboxLabel">
                <span>{{ $t('influencer.activate05') }}</span>
                <a :href="`/${$i18n.locale}/lp/cgs/`" target="_blank">
                  <span class="cgsLink">{{ $t('influencer.activate06') }}</span>
                </a>
              </div>
            </template>
          </VInputCheckboxRequired>
        </div>

        <div class="actionWrap">
          <BtnOrange type="submit">
            {{ $t('shared.parameter.accountPasswordEdit04') }}
          </BtnOrange>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { useForm } from 'vee-validate'

import { BtnOrange } from '~/components/ui/Buttons'
import VInputCheckboxRequired from '~/components/ui/Inputs/ValidationFields/VInputCheckboxRequired.vue'
import VInputEmail from '~/components/ui/Inputs/ValidationFields/VInputEmail.vue'
import VInputPassword from '~/components/ui/Inputs/ValidationFields/VInputPassword.vue'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

type ActivateFormFields = {
  email: string
  password: string
  cgs: boolean
}
export default defineNuxtComponent({
  components: {
    BtnOrange,
    VInputCheckboxRequired,
    VInputEmail,
    VInputPassword,
  },
  setup() {
    definePageMeta({
      middleware: [
        (to) => {
          const { $localePath } = useNuxtApp()

          if (!to.query.uid || !to.query.token)
            return navigateTo($localePath('/'))
        },
      ],
    })

    const { coreFetch } = useProvideCoreFetch()
    const localePath = useLocalePath()

    const { validate: validateForm } = useForm<ActivateFormFields>({})

    return {
      coreFetch,
      localePath,
      validateForm,
    }
  },
  data() {
    return {
      isLoading: false,
      cgs: false,
      email: '',
      emailRefineMessage: '',
      password: '',
    }
  },
  methods: {
    async validateEmail(email: string): Promise<boolean> {
      // Btw, this is terrible, but we can't seem to get the email to stop validating
      // on every keypress, so we have to do this.
      if (!this.isLoading) return true

      try {
        await this.coreFetch.$get(`/auth/user/${email}/`)
        this.emailRefineMessage = this.$t('influencer.activate07')
        return false
      } catch (err) {
        if ((err as Error).message.includes('404')) return true

        this.emailRefineMessage = this.$t('errors.pleaseTryAgain')
        return false
      }
    },
    async finalize() {
      if (this.isLoading) return

      this.isLoading = true

      const { valid } = await this.validateForm()
      if (!valid) {
        this.isLoading = false
        return
      }

      const resp = await this.coreFetch.$post(
        '/auth/activate/' +
          this.$route.query.uid +
          '/' +
          this.$route.query.token +
          '/',
        {
          email: this.email,
          password: this.password,
        },
      )

      this.isLoading = false

      return navigateTo(
        this.localePath('/influencer/profile/' + resp.slug + '/'),
      )
    },
  },
})
</script>

<style scoped>
.influencerActivateWrapper .innerAdapt {
  margin-top: 120px;
}

.influencerActivateWrapper .widthLimit {
  background-color: var(--color-white);
  max-width: 500px;
  margin: 0px auto;
  border: 1px solid var(--color-ebb-grey);
  padding: 32px;
}

.title {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-black);
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--color-ebb-grey);
  text-align: center;
  width: 100%;
}

.cbWrap {
  margin-bottom: 48px;
}

.checkboxLabel {
  margin-left: 8px;
}

.cgsLink {
  text-decoration: underline;
}
</style>
<style scoped src="~/assets/helpers/redundantBlocks.css"></style>
