import type { APICart, CartItemIdentifier } from '~/types/cart'
import type { CoreFetch } from '~/types/CoreFetch'

export function providePatchCart(coreFetch: CoreFetch) {
  return function (cartId: number, cart: Partial<APICart>) {
    return coreFetch.$patch<APICart>(`/cart/cart/${cartId}/`, {
      cart,
    })
  }
}

export function provideAddItemToCart(coreFetch: CoreFetch) {
  return function (cartId: number, itemIdentifier: CartItemIdentifier) {
    return coreFetch.$post<APICart>(
      `/cart/cart/${cartId}/item/`,
      itemIdentifier,
    )
  }
}

export function provideRemoveItemFromCart(coreFetch: CoreFetch) {
  return function (itemId: number) {
    return coreFetch.$delete<APICart>(`/cart/item/${itemId}/`)
  }
}

export function providePayCartWithWallet(coreFetch: CoreFetch) {
  return function (cartId: number) {
    return coreFetch.$post<APICart>(`/cart/cart/${cartId}/pay/`)
  }
}
