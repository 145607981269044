/* eslint-disable camelcase */
import { defineStore } from 'pinia'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { provideGetCartCheckout } from '~/api-core/Cart/FetchCart'

import type { APICartCheckout, CartCheckout } from '~/types/cart'

export const useCartCheckoutStore = defineStore('cartCheckout', () => {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()

  const cartStore = useCartStore($pinia)
  const userStore = useUserStore($pinia)

  const cart_price = ref(0)
  const minimum_cart_cost = ref(0)
  const groovies_to_spend = ref(0)
  const groovies_to_pay_with_wallet = ref(0)
  const amount_to_pay = ref(0)
  const groovies_left_after_payment = ref(0)
  const paypal_fees = ref(1.5)

  function SET(cartCheckout: APICartCheckout | CartCheckout) {
    cart_price.value = cartCheckout.cart_price
    minimum_cart_cost.value = cartCheckout.minimum_cart_cost
    groovies_to_spend.value = cartCheckout.groovies_to_spend
    groovies_to_pay_with_wallet.value = cartCheckout.groovies_to_pay_with_wallet
    amount_to_pay.value = cartCheckout.amount_to_pay
    groovies_left_after_payment.value = cartCheckout.groovies_left_after_payment
    paypal_fees.value = cartCheckout.paypal_fees
  }

  async function FETCH_CART_CHECKOUT() {
    try {
      const checkout = await provideGetCartCheckout(coreFetch)(cartStore.id)
      if (checkout.id === cartStore.id) SET(checkout)
      await userStore.UPDATE_GROOVIZ()
    } catch (_) {}
  }

  const $state = computed(() => ({
    cart_price: cart_price.value,
    minimum_cart_cost: minimum_cart_cost.value,
    groovies_to_spend: groovies_to_spend.value,
    groovies_to_pay_with_wallet: groovies_to_pay_with_wallet.value,
    amount_to_pay: amount_to_pay.value,
    groovies_left_after_payment: groovies_left_after_payment.value,
    paypal_fees: paypal_fees.value,
  }))

  return {
    // state
    cart_price,
    minimum_cart_cost,
    groovies_to_spend,
    groovies_to_pay_with_wallet,
    amount_to_pay,
    groovies_left_after_payment,
    paypal_fees,

    // getters
    $state,

    // actions
    FETCH_CART_CHECKOUT,
    SET,
  }
})
